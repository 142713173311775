import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

import Logo from '../Logo';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import './styles.scss';

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();

  return (
    <div className="footer">
      <Row className="mt-1 mb-2 footerRow">
        <Col sm={12} md={12} lg={3}>
          <Row className="mb-4">
            <Logo cy="test-logo-footer" isFooter />
          </Row>
          <Row>
            <span>WoodFlow Brazil LTDA ©2022</span>
            <span>CNPJ 45.060.880/0001-20</span>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={3}>
          <Row>
            <strong>WoodFlow</strong>
          </Row>
          <Row>
            <Link
              to={{ pathname: 'https://woodflow.com.br/blog/' }}
              target="_blank"
              onClick={() => {
                trackEvent('Blog', {
                  action: 'O usuário clicou no botão, que fica no footer com redirecionamento para o blog',
                });
              }}
            >
              <span>{t('footer.labelBlog')}</span>
            </Link>
            <Link
              to={{ pathname: 'mailto://woodflow@woodflow.com.br' }}
              target="_blank"
              onClick={() => {
                trackEvent('Contact', {
                  action: 'Clicou no botão de "Contato" no footer',
                });
              }}
            >
              <span>{t('footer.labelContatct')}</span>
            </Link>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={3}>
          <Row>
            <strong>{t('footer.labelPolicies')}</strong>
          </Row>
          <Row>
            <Link to={{ pathname: 'https://www.woodflow.com.br/privacy-policy/' }} target="_blank">
              <span>{t('footer.labelPrivacyPolicy')}</span>
            </Link>
            <Link to={{ pathname: 'https://woodflow.com.br/politica-de-cookies/' }} target="_blank">
              <span>{t('footer.labelTermsOfUse')}</span>
            </Link>
          </Row>
        </Col>
        <Col sm={12} md={12} lg={3} className="d-flex flex-row">
          <Link
            className="linkUtil"
            to={{ pathname: 'https://www.facebook.com/woodflowbrazil/' }}
            title="Facebook"
            target="_blank"
          >
            <FaFacebookF />
          </Link>
          <Link
            className="linkUtil"
            to={{ pathname: 'https://www.instagram.com/woodflowbrazil/' }}
            title="Instagram"
            target="_blank"
          >
            <FaInstagram />
          </Link>
          <Link
            className="linkUtil"
            to={{ pathname: 'https://www.linkedin.com/company/woodflowbrazil' }}
            title="Linkedin"
            target="_blank"
          >
            <FaLinkedinIn />
          </Link>
          <Link
            className="linkUtil"
            to={{ pathname: 'https://www.youtube.com/channel/UCw59XEaRaXvjt3fhfQDGCJw' }}
            title="Youtube"
            target="_blank"
          >
            <FaYoutube />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
