import HttpClient from './httpClient';
import { IShipmentScheduleColumn, IUser } from '../interfaces';
import { IResponseUser } from '../interfaces/IResponseUser';

export interface IProductPreference {
  products?: Array<number>;
}

class UsersService {
  static async checkToken(): Promise<IUser> {
    const { data } = await HttpClient.api.get<IResponseUser>('/users/usuarioLogado');

    const userStorage = {
      id: data.usuario_id,
      profileId: data.perfil_id,
      displayName: data.nom_usuario,
      email: data.des_usuario_acesso,
      avatarUrl: data.arq_imagem_usuario,
      phone: data.num_celular,
      language: data.cod_lingua,
      address: data.json_endereco,
      shipmentScheduleColumns: JSON.parse(data.shipment_schedule_columns),
      default_company: data.default_company,
      first_login_at: data.first_login_at,
      adm_usuario_rel: data.adm_usuario_rel || null,
      status_profile: data.status_profile || null,
      perfil_id: data.perfil_id,
      accept_privacy_policies_at: data.accept_privacy_policies_at,
    };

    return userStorage;
  }

  static async users(): Promise<IUser[]> {
    const { data } = await HttpClient.api.get<IUser[]>('/users');
    return data;
  }

  static async user(id: string): Promise<IUser> {
    const { data } = await HttpClient.api.get(`/users/${id}`);
    return data;
  }

  static async create(name: string, lastName: string, email: string): Promise<void> {
    const obj = {
      first_name: name,
      last_name: lastName,
      email,
    };
    const { data } = await HttpClient.api.post('/users', obj);
    return data;
  }

  static async update(name: string, lastName: string, email: string, id: string): Promise<void> {
    const obj = {
      first_name: name,
      last_name: lastName,
      email,
    };
    const { data } = await HttpClient.api.put(`/users/${id}`, obj);
    return data;
  }

  static async delete(id: string): Promise<string> {
    const { statusText } = await HttpClient.api.delete(`/users/${id}`);
    return statusText;
  }

  static async getResponsiblePerson(): Promise<IUser[]> {
    const { data: result } = await HttpClient.api.get('/users/usuarioResponsavel');

    const userStorage = result.data.map((values: any) => ({
      id: values.usuario_id,
      profileId: values.perfil_id,
      displayName: values.nom_usuario,
      email: values.des_usuario_acesso,
      avatarUrl: values.arq_imagem_usuario,
      phone: values.num_celular,
      language: values.cod_lingua,
      address: values.json_endereco,
      adm_usuario_rel: values.adm_usuario_rel || null,
      perfil_id: values.perfil_id,
      accept_privacy_policies_at: values.accept_privacy_policies_at,
    }));

    return userStorage;
  }

  static async setShipmentScheduleColumns(columns: IShipmentScheduleColumn[]): Promise<IUser> {
    const { data } = await HttpClient.api.patch(`/users/shipmentScheduleConfig`, {
      columns,
    });

    return {
      id: data.usuario_id,
      profileId: data.perfil_id,
      displayName: data.nom_usuario,
      email: data.des_usuario_acesso,
      avatarUrl: data.arq_imagem_usuario,
      phone: data.num_celular,
      language: data.cod_lingua,
      address: data.json_endereco,
      shipmentScheduleColumns: JSON.parse(data.shipment_schedule_columns),
      adm_usuario_rel: data.adm_usuario_rel || null,
    };
  }

  static async setLanguage(lang: string): Promise<IUser> {
    const { data } = await HttpClient.api.patch('/users/language', {
      lang,
    });

    return data;
  }

  static async setFirstLogin(): Promise<IUser> {
    const { data } = await HttpClient.api.patch('/users/firstLogin');

    return data;
  }

  static async setPrivacyPolicies(): Promise<IUser> {
    const { data } = await HttpClient.api.patch('/users/privacyPolicies');

    return data;
  }

  static async setProductsPreferences(body: IProductPreference): Promise<IUser> {
    const { data } = await HttpClient.api.patch('/users/product_preferences', {
      ...body,
    });

    return data;
  }

  static async setDefaultCompany(uuid: string): Promise<IUser> {
    const { data } = await HttpClient.api.patch('/users/defaultCompany', {
      company: uuid,
    });

    return data;
  }
}

export default UsersService;
