import React from 'react';

interface IContext {
  setPage: (page: number) => void;
  page: number;
  lastPage: number;
  setLastPage: (lastPage: number) => void;
  sortType: any;
  setSortType: (sortType: any) => void;
  sortColumn: any;
  setSortColumn: (sortColumn: any) => void;
}

interface IProvider {
  children: React.ReactNode | React.ReactNode[];
}

const filterBusinessContext = React.createContext<IContext>({} as IContext);

export function useFilterBusiness(): IContext {
  return React.useContext(filterBusinessContext);
}

export function BusinessProvider({ children }: IProvider): React.ReactElement {
  const [page, setPage] = React.useState<number>(1);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [lastPage, setLastPage] = React.useState<number>(1);

  const value = {
    page,
    setPage,
    sortType,
    setSortType,
    sortColumn,
    setSortColumn,
    lastPage,
    setLastPage,
  };

  return <filterBusinessContext.Provider value={value}>{children}</filterBusinessContext.Provider>;
}
