import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Container, Navbar, Nav, Col, Image, NavDropdown } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { MdLogout } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Text from '../Text';
import SelectLanguage from '../SelectLanguage';
import Button from '../Button';
import Logo from '../Logo';
import LogoHeader from '../../statics/logo-header.png';
import Notify from '../../statics/ic-notify.svg';
import { useAuth } from '../../contexts/AuthContext';
import { useLoader } from '../../contexts/LoaderContext';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import CompaniesService from '../../services/companies.service';
import UsersService from '../../services/users.service';
import formatLimitString from '../../utils/formatLimitString';
import getInitials from '../../utils/getInitials';
import { ICompanyServiceResponse, IUser } from '../../interfaces';
import './styles.scss';

interface IHeader {
  isLogged?: boolean;
}

const Header = ({ isLogged = false }: IHeader): React.ReactElement => {
  const { user, signOut, updateUser } = useAuth();
  const { setShowLoader } = useLoader();
  const { trackEvent } = useAnalytics();
  const { shutdown } = useIntercom();

  const history = useHistory();
  const [filterCompanies, setFilterCompanies] = React.useState<ICompanyServiceResponse | undefined>();
  const [userValid, setUserValid] = React.useState<IUser>(user);
  const [typeUser, setTypeUser] = React.useState('');

  React.useEffect(() => {
    if (user?.profileId >= 9) {
      setTypeUser('seller');
    }

    if (user?.profileId > 3 && user?.profileId < 9) {
      setTypeUser('customer');
    }

    if (user?.profileId <= 3) {
      setTypeUser('admin');
    }
  }, [user?.profileId]);

  const fetchCompanies = React.useCallback(async (): Promise<ICompanyServiceResponse> => {

    if (!user){
      return {} as ICompanyServiceResponse;
    }

    try {
      const res = await CompaniesService.getCompanies('', typeUser === 'seller' ? 'seller' : 'customer', 1, 200);
      if (res) {
        return res;
      }
      return {} as ICompanyServiceResponse;
    } catch (error) {
      if (user) {
        signOut();
      }
      return {} as ICompanyServiceResponse;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOut, typeUser]);

  React.useEffect(() => {
    let isCleaningUp = false;

    async function loadCompanies(): Promise<void> {
      const res = await fetchCompanies();
      if (res && !isCleaningUp) {
        setFilterCompanies(res);
      }
    }

    loadCompanies();

    return () => {
      isCleaningUp = true;
    };
  }, [fetchCompanies]);

  const handleChangeCompany = React.useCallback(
    async (uuid: string): Promise<void> => {
      setShowLoader(true);
      await UsersService.setDefaultCompany(uuid);

      const res = await UsersService.checkToken();

      if (res) {
        setUserValid(res);

        await updateUser(res);

        history.go(0);

        setShowLoader(false);
      }
    },
    [history, setShowLoader, updateUser]
  );

  React.useEffect(() => {
    async function loadDefaultCompanies(): Promise<void> {
      if (user && !user.default_company && filterCompanies) {
        await handleChangeCompany(String(filterCompanies?.data[0].uuid));
      } else {
        setUserValid(user);
      }
    }

    loadDefaultCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCompanies]);

  const { t } = useTranslation();

  return (
    <Navbar bg="transparent" className="navbar-top p-3 border-0" expand="lg" fixed="top">
      <Container className="containerAlign">
        <Col className="colMobileLogo">
          <div className="navbar-top--button">
            <Navbar.Brand href={typeUser === 'customer' ? '/shipment-schedule' : '/dashboard'}>
              <Logo cy="logo" src={LogoHeader} height={30} />
            </Navbar.Brand>
          </div>
        </Col>
        {!isLogged && (
          <>
            <div className="alignResponsiveHeader">
              <SelectLanguage classes="navbar-top--showMobile" isLogged={false} />
              <Navbar.Toggle />
            </div>
            <div className="nav__grid-items justify-content-end align-items-center">
              <Navbar.Collapse>
                <SelectLanguage classes="navbar-top--showAll" isLogged={false} />
              </Navbar.Collapse>
            </div>
          </>
        )}

        {isLogged && (
          <>
            <div className="alignResponsiveHeader">
              <SelectLanguage classes="navbar-top--showMobile" isLogged />
              <Navbar.Toggle />
            </div>

            <Navbar.Collapse className="dropDownMobile">
              <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                <NavLink
                  to="/dashboard"
                  className="navbar-top--link mt-3"
                  activeClassName="navbar-top--link__active"
                  onClick={() => {
                    trackEvent('Dashboard', {
                      action: 'Dashboard',
                    });
                  }}
                >
                  {t('language') === 'USA' ? 'Home' : 'Início'}
                </NavLink>
                <NavLink
                  to={typeUser === 'seller' || typeUser === 'admin' ? '/sales' : '/shipment-schedule'}
                  className="navbar-top--link mt-3"
                  activeClassName="navbar-top--link__active"
                  onClick={() => {
                    trackEvent('View SS - SS', {
                      action: 'View SS - SS',
                    });
                  }}
                >
                  {typeUser === 'seller' || typeUser === 'admin' ? t('headers.sales') : t('headers.businessDashboard')}
                </NavLink>

                {typeUser !== 'seller' && typeUser !== 'admin' && typeUser !== 'customer' ? (
                  <>
                    <NavLink
                      to="/quotes"
                      className="navbar-top--link mt-3"
                      activeClassName="navbar-top--link__active"
                      onClick={() => {
                        trackEvent('View Quotes - Menu', {
                          action: 'View Quotes - Menu',
                        });
                      }}
                    >
                      {t('titles.quotes')}
                    </NavLink>
                    <NavLink
                      to="/products"
                      className="navbar-top--link mt-3"
                      activeClassName="navbar-top--link__active"
                      onClick={() => {
                        trackEvent('Products - Menu', {
                          action: 'Products - Menu',
                        });
                      }}
                    >
                      {t('modal.modalTitleProducts')}
                    </NavLink>
                  </>
                ) : (
                  <> </>
                )}

                {typeUser !== 'seller' && typeUser !== 'admin' && typeUser !== 'customer' ? (
                  <NavLink
                    to="/suppliers"
                    className="navbar-top--link mt-3"
                    activeClassName="navbar-top--link__active"
                    onClick={() => {
                      trackEvent('Supplier - Menu', {
                        action: 'Supplier - Menu',
                      });
                    }}
                  >
                    {t('titles.suppliers')}
                  </NavLink>
                ) : (
                  <>
                    {(typeUser === 'seller' || typeUser === 'admin') && (
                      <>
                        <NavLink
                          to="/clients"
                          className="navbar-top--link mt-3"
                          activeClassName="navbar-top--link__active"
                          onClick={() => {
                            trackEvent('Clients - Menu', {
                              action: 'Clients - Menu',
                            });
                          }}
                        >
                          {t('titles.clients')}
                        </NavLink>
                        <NavLink
                          to="/my-products"
                          className="navbar-top--link mt-3"
                          activeClassName="navbar-top--link__active"
                          onClick={() => {
                            trackEvent('Products - Menu', {
                              action: 'Products - Menu',
                            });
                          }}
                        >
                          {t('modal.modalTitleProducts')}
                        </NavLink>
                      </>
                    )}
                  </>
                )}

                {typeUser === 'seller' || typeUser === 'admin' ? (
                  <>
                    <NavLink
                      to="/ports"
                      className="navbar-top--link mt-3"
                      activeClassName="navbar-top--link__active"
                      onClick={() => {
                        trackEvent('Ports - Menu', {
                          action: 'Ports - Menu',
                        });
                      }}
                    >
                      {t('titles.port')}
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}
              </Nav>

              <div className="colMobile">
                <Button data-sleek-changelog cy="btn-lg-ptbr-test" type="button" variant="btn-transparent">
                  <Image src={Notify} />
                </Button>
              </div>
              <SelectLanguage classes="navbar-top--showAll" isLogged />
              <div className="line" />
              <div className="initialName">
                <div className="d-flex align-items-center justify-content-center">
                  <Text as="b" weight={400} size="1.3rem" className="abreviationUser">
                    {user.profileId <= 3
                      ? getInitials(user.displayName)
                      : getInitials(userValid?.default_company?.name) || getInitials(user.displayName)}
                  </Text>
                </div>
              </div>
              <div className="dropDownAlign">
                {(filterCompanies?.data?.length as any) > 0 && user.profileId > 3 ? (
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={formatLimitString(userValid?.default_company?.name, 34)}
                  >
                    <NavDropdown.Item className="alignItemsDropDown">
                      <div className="alignTextCompanySelected">
                        {formatLimitString(userValid?.default_company?.name, 24)}
                        <p> {formatLimitString(userValid?.default_company?.email, 34)}</p>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {filterCompanies?.data.map((item) => (
                      <>
                        <NavDropdown.Item onClick={() => handleChangeCompany(String(item.uuid))}>
                          <Text as="b" weight={600} size="0.85rem">
                            {formatLimitString(item.name, 30)}
                          </Text>
                          <br />
                          <p>
                            {formatLimitString(item.email, 34)} <br />
                            {item.id}
                          </p>
                          <NavDropdown.Divider />
                        </NavDropdown.Item>
                      </>
                    ))}
                    <NavDropdown.Item
                      className="logout"
                      fixed="bottom"
                      onClick={() => {
                        shutdown();
                        signOut();
                      }}
                    >
                      <Text as="small" color="#54666F">
                        Logout
                      </Text>
                      {'  '}
                      <MdLogout color="#54666F" size={20} />
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavDropdown title={user.profileId <= 3 ? user.displayName : ''}>
                    <NavDropdown.Item
                      className="logout"
                      onClick={() => {
                        signOut();
                      }}
                    >
                      <Text as="small" color="#54666F">
                        Logout
                      </Text>
                      {'  '}
                      <MdLogout color="#54666F" size={20} />
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </div>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
