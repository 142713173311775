import React, { lazy } from 'react';

const Products = lazy(() => import('../pages/Products/List'));
const ShipmentSchedule = lazy(() => import('../pages/ShipmentSchedule'));
const Quotes = lazy(() => import('../pages/Quotes'));
const NewQuote = lazy(() => import('../pages/Quotes/NewQuote'));
const Suppliers = lazy(() => import('../pages/Suppliers/List'));
const SuppliersProfile = lazy(() => import('../pages/Suppliers/Profile'));
const ConfirmAccount = lazy(() => import('../pages/Authentication/ConfirmAccount'));
const Dashboard = lazy(() => import('../pages/NewDashboard'));
const Metrics = lazy(() => import('../pages/Dashboard/Metrics'));
const DetailsShipmentSchedule = lazy(() => import('../pages/ShipmentSchedule/Details'));
const DetailsUnauthenticated = lazy(() => import('../pages/ShipmentSchedule/DetailsUnauthenticated'));
const DetailsQuote = lazy(() => import('../pages/Quotes/Details'));
const Login = lazy(() => import('../pages/Authentication/Login'));
const ForgotPassword = lazy(() => import('../pages/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Authentication/ResetPassword'));

const Error = lazy(() => import('../pages/Error'));

interface IProp {
  path: string;
  component: React.FunctionComponent;
  public?: boolean;
}

export const customerRoutes: IProp[] = [
  {
    path: `/business-shared/*`,
    component: DetailsUnauthenticated,
    public: true,
  },
  {
    path: '/products',
    component: Products,
  },
  {
    path: '/metrics',
    component: Metrics,
  },
  {
    path: '/suppliers-profile/:id',
    component: SuppliersProfile,
  },
  {
    path: '/suppliers',
    component: Suppliers,
  },
  {
    path: '/shipment-schedule',
    component: ShipmentSchedule,
  },
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/quotes',
    component: Quotes,
  },
  {
    path: `/quote/:id`,
    component: DetailsQuote,
  },
  {
    path: '/new-quote',
    component: NewQuote,
  },

  {
    path: '/confirm-account',
    component: ConfirmAccount,
    public: true,
  },
  {
    path: `/business/:id`,
    component: DetailsShipmentSchedule,
  },
  {
    path: '/business/shared/:hash',
    component: ShipmentSchedule,
  },
  {
    path: '/business/shared/:hash/documents',
    component: ShipmentSchedule,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    public: true,
  },
  {
    path: '/resetPassword/:token',
    component: ResetPassword,
    public: true,
  },
  {
    path: '/registration/:token',
    component: ResetPassword,
    public: true,
  },
  {
    path: '/login/:hash?',
    component: Login,
    public: true,
  },
  {
    path: '/login/new-quote',
    component: Login,
    public: true,
  },
  {
    path: '*',
    component: Error,
    public: true,
  },
];
