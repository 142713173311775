import React, { createContext, useCallback, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../interfaces';

import authService from '../../services/auth.service';
import HttpClient from '../../services/httpClient';
import UsersService from '../../services/users.service';
import { useAnalytics } from '../AnalyticsContext';

interface SignInCredential {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredential): Promise<IUser>;
  signOut(): void;
  updateUser(data: IUser): Promise<void>;
}

interface AuthState {
  token: string;
  user: IUser;
}

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const { identify, setPeople, trackEvent } = useAnalytics();
  const { i18n } = useTranslation();

  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@WoodFlowApp:token');
    const user = localStorage.getItem('@WoodFlowApp:user');

    if (token && user) {
      HttpClient.api.defaults.headers.common.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password }: SignInCredential): Promise<IUser> => {
      const authToken = await authService.sign(email, password);

      localStorage.setItem('@WoodFlowApp:token', authToken.access_token);

      HttpClient.api.defaults.headers.common.authorization = `Bearer ${authToken.access_token}`;

      const user = await UsersService.checkToken();

      identify(user.email);
      setPeople('$name', user.displayName);
      setPeople('$email', user.email);

      trackEvent('Login', {
        action: 'Fez login',
      });

      localStorage.setItem('@WoodFlowApp:user', JSON.stringify(user));

      i18n.changeLanguage(user.language);

      setData({ token: authToken.access_token, user });

      return user;
    },
    [i18n, identify, setPeople, trackEvent]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@WoodFlowApp:token');
    localStorage.removeItem('@WoodFlowApp:user');
    localStorage.clear();

    setData({} as AuthState);

    history.push('/login');
  }, [history]);

  const updateUser = useCallback(
    async (user: IUser) => {
      setData({
        token: data.token,
        user,
      });

      localStorage.setItem('@WoodFlowApp:user', JSON.stringify(user));
    },
    [data.token]
  );

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>{children}</AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
