export const messages = {
  en: {
    translations: {
      language: 'USA',
      selectTranslation: {
        translate: 'Select the language',
      },
      format: {
        date: 'MMM/dd/yyyy',
      },
      tags: {
        titleRegister: 'Register',
      },
      business: {
        selectFreit: 'Select shipping',
        freit: 'Freight',
        noPrevision: 'No forecast',
        statsActual: 'Current status',
        messageExplainStats: 'The status represents that the cargo is present at its final destination.',
        etdExplain: 'ETD - Boarding',
        datePrev: 'Expected date',
        etaShipper: 'ETA - Delivery',
        historiesActualize: 'Update history',
        typeFollow: 'Type of follow up',
        removeFollow: 'Remove follow up',
        historyAtt: 'Update history',

        obsHolder: 'Add additional information here (max. 255 characters)',
        newStatus: 'New status',
        obsOptional: 'Notes (optional)',
        typeFollowUp: 'Follow Up Type',
        generateProforma: 'Generate Proforma',
        dateEstimatedBusiness: 'Estimated boarding date',
        tolerancem3: 'Tolerance m³',
        sendTolerancem3: 'Enter the tolerance in m³',
        totalM3: 'Total m³ shipped',
        consignee: 'Consignee (optional)',
        notify: 'Notify 1 (optional)',
        notify2: 'Notify 2 (optional)',
        docsEmitProforma: 'Documents to be issued - to be included in the Pro forma',
        obsProforma: 'Observations to be included in the Pro forma',
        sendDocsEmitProforma: 'Add the documents to be issued here. Ex: Invoice, Packing List, BL, certificates...',
        infoSale: 'Business information',
        docsEmit: 'Documents to be issued',
        information: 'Information',
        backToForm: 'Back to form',
        backToBusiness: 'Back to sales',
        infoImporterEmail: 'The importer with the registered email will track the order details.',
        sendTo: 'Send to',
        addEmail: 'Enter the e-mail here',
        pleaseAddFourEmails: 'You can add up to 4 e-mails per order.',
        businessMessageSupport: 'Registered e-mails will be able to track the details of this order.',
        messageReallyDelete: 'Are you sure you want to delete the deal? ',
        confirmReallyDelete:
          'After deletion, you will lose all registered information and will have to add them again.',
        sendTotalVolume: 'Enter the volume in m³',
        nameFile: 'File name',
        newBusiness: 'New business',
        editBusiness: ' Edit business',
        infoBusiness: 'Business information',
        responsible: 'Responsible for the business',
        sendResponsible: 'Enter the name of the person responsible for the business',
        po: 'PO (Purchase Order)',
        sendPo: 'Enter the purchase order code',
        importer: 'Importer*',
        sendImporter: 'Enter the name of the importer',
        contactImporter: 'Importer contact',
        sendContactImporter: "Add the importer's contact",
        obs: 'Observations',
        sendObs: 'Add additional information about the business here (max. 255 characters)',
        products: 'Products',
        selectProduct: 'Select a product for the business:',
        specie: 'Species*',
        selectSpecie: 'Select a species for the product',
        acabaments: 'Finish(es)',
        selectAcabaments: 'Select one or more finishes for the product',
        qualities: 'Quality(ies)',
        selectQualities: 'Select one or more qualities for the product',
        certificates: 'Certificate(s)',
        selectCertificates: 'Select one or more certificates for the product',
        glue: 'Glue',
        selectGlue: 'Select the glue for the product',
        thickness: 'Thickness(es)',
        layers: 'Layer(s)',
        selectLayers: 'Select layers',
        width: 'Width(s)',
        length: 'Length(s)',
        holderMms: 'Unit in mm',
        m3OrPieces: 'M³ or pieces',
        totalVolumem3: 'Total volume M³',
        qtdPieces: 'Quantity of pieces',
        sendQtdPieces: 'Enter the number of pieces',
        qtdPalet: 'Quantity of pallets',
        numberOfPallets: 'Enter the number of pallets',
        qtdPiecesPalet: 'Quantity of pieces per pallet',
        numberOfPiecesPallet: 'Enter the auantity of pieces per pallet',
        priceUnit: 'Unit price (USD$)',
        totalPrice: 'Total price',
        removeProduct: 'Remove product',
        removeDocumentEUDR: 'Remove document',
        questionRemove: 'Are you sure you want to remove the product from this sale?',
        questionRemoveDocument: 'You are about to delete a document, are you sure about this action?',

        affirmativeRemove:
          ' After deleting the product, you will lose the registered information and will need to add them again.',
        quantity: 'Quantity pallets:',
        editProduct: 'Edit product',
        paymentMethod: 'Payment method',
        sendPaymentMethod: 'Enter the payment method',
        totalValue: 'TOTAL VALUE',
        totalAmount: 'TOTAL AMOUNT',
        valueAdiant: 'Advance payment amount',
        statusPayment: 'Payment status',
        sendStatusPayment: 'Enter the payment status',
        origin: 'Place of origin',
        selectOrigin: 'Select the place of origin',
        final: 'Final destination',
        selectFinal: 'Select the final destination',
        port: 'Port of shipment',
        selectPort: 'Select the port of shipment',
        portDestine: 'Port of destination',
        selectPortDestine: 'Select the port of destination',
        selectInconterm: 'Select the Incoterm',
        sendBooking: 'Enter the booking of the order',
        sendInvoice: 'Enter the invoice code',
        typeContainer: 'Container type',
        selectTypeContainer: 'Select the type of container used',
        qtdContainer: 'Quantity of containers',
        sendQtdContainer: 'Enter the number of containers',
        qtdM3: 'Quantity in M³',
        sendQtdM3: 'Enter the quantity in M³',
        nameShip: 'Ship name',
        sendNameShip: 'Enter the ship name',
        transporter: 'Transporter',
        sendTransporter: 'Enter the name of the transporter',
        valueFreit: 'Freight value',

        infoBalance: 'Amount referring to the total amount - advance amount.',
        infoTotalAmount: 'Total value referring to the sum of products added.',

        provider: 'Supplier',
        duplicate: 'Duplicate',

        nameDescProForma: 'Product Description',
        sendNameDescProForma: 'Enter the product description',
        nameInsurance: 'Insurance',
        sendInsurance: 'Enter the Insurance value',
        nameLastDateShipment: 'Last date shipment',
        sendLastDateShipment: 'Enter the last date shipment',
        nameTranshipment: 'Transhipment',
        sendTranshipment: 'Enter the transhipment value',
        namePartialShipments: 'Partial shipment',
        sendPartialShipments: 'Enter the Partial shipment',
        nameDynamicTitle: 'Dynamic title',
        sendNameDynamicTitle: 'Enter title',
        nameDynamicDescription: 'Dynamic description',
        sendNameDynamicDescription: 'Enter description',
      },
      steps: {
        forest: 'Step 1:  Forest of Harvest',
        processing: 'Step 2: Exporter',
        export: 'Step 3: Export',
        details: 'Details',
        exporter: 'Exporter',
        address: 'Exporter address',
        importer: 'Importer',
        provider: 'Supplier',
        addressImporter: 'Importer address',
      },
      headers: {
        businessDashboard: 'Shipment Schedule',
        goodMorning: 'Good morning',
        goodAfternoon: 'Good afternoon',
        goodNight: 'Good night',
        createAccount: 'Create account',
        joinWoodflow: 'Join Woodflow.',
        newCreateAccount:
          'Requesting and receiving quotes, negotiating prices, and making deals is now easier than ever.',
        sales: 'Sales',
        profile: 'Profile',
        newDashboardSales: 'Latest Sales',
        newDashboardBusinessDashboard: 'Latest Shipment Schedule',
      },
      card: {
        title: 'We have more to offer!',
        action: 'Click here',
        subtitle: 'and check all our listed products.',
        viewAllProducts: 'view all products',
      },
      footer: {
        labelBlog: 'Blog',
        labelContatct: 'Contact',
        labelPolicies: 'Policies',
        labelPrivacyPolicy: 'Privacy Policy',
        labelTermsOfUse: 'Terms of Use',
      },
      selects: {
        proforma: 'Proforma',

        operatingLicense: 'Operating license',
        fscCertified: 'FSC Certificate',
        other: 'Others',
        propertyRegistration: 'Property registration',
        car: 'CAR',
        audits: 'Audits',
        forestAgreement: 'Forest purchase and sale agreement',
        invoiceLogs: 'DANFE of timber logs',
        invoice: 'Invoice',
        selectEutr: 'Select product documents',
        open_balance: 'OPEN BALANCE  ',
        to_be_paid: 'TO BE PAID',
        paid: 'PAID',
        open: 'OPEN',
      },
      titles: {
        titleEmails: 'Enter emails for order tracking',
        infoBusiness: 'Business information',

        contactCard: 'Company contact details',

        clientInfos: 'Company details',

        clients: 'Clients',
        products: 'Products',
        supply: 'Supply chain documents',

        portinfos: 'Port details',
        port: 'Ports',
        editPort: 'Edit port',

        deleteDocument: 'Delete document',
        deleteFollow: 'Delete Follow Up',

        deleteBusiness: 'Delete business',

        verifyAccount: 'Verified account!',
        messageVerify: 'Welcome! Enjoy WoodFlow plataform!',
        certificate: 'Certificate',
        exporter: 'Exporter',
        florest: ' Forest of Harvest',
        processing: 'Exporter',
        importing: 'Import',

        eutr: 'Supply chain documents',
        inspection: 'Inspection',
        newProduct: 'New product',
        editProduct: 'Product details',
        supplier: 'Supplier',
        suppliers: 'Suppliers',
        responseWoodflow: 'Justification',
        resume: 'Resume',
        quoteDetail: 'Quote detail',
        app: 'Employees',
        businessData: 'Business data',
        businessShipData: 'Shipment data',
        businessProductData: 'Product data',
        businessPlannerData: 'Planner',
        businessPaymentData: 'Payment terms',
        shippingInformation: 'Shipping information',
        paymentsTerms: 'Payments terms',
        documents: 'Shipment Documents',
        cargoDescription: 'Cargo description',
        shippingDocuments: 'Shipping documents',
        actions: 'Actions',
        haveAccount: 'Have an account? Login in',
        quotes: 'View Quotes',
        item1: 'Search for products and learn about the supplier prior to making deals',
        item2: 'Request quotes, negotiate, and opt for the best offers',
        item3: 'End-to-end track your orders in one place',
        item4: 'Have valuable information at a glance through graphs, charts, and a complete dashboard',
      },
      messages: {
        accessNotAutorized: 'Unauthorized access',

        informationDays: 'ESD in {{days}} day(s).',

        alertNoRemoveFollowUp: 'This Follow Up was sent by email and can no longer be deleted.',
        followRemoved: 'Follow Up successfully removed',

        loadingPrevShipped: 'Waiting for estimated shipped',
        loadingPrevArival: 'Waiting for estimated arrival',
        docRemoved: 'Document deleted successfully.',

        clickToSave: 'Click on save, so that the products are listed in the Supply Chain',

        removedSucess: 'Successfully removed',

        noMoreOptions: 'no options registered',

        errorInspetor: 'Inspector user does not have permission to access the platform.',

        loadingVerify: 'Almost there! We are verifiying your company details!',
        textExplication:
          'WoodFlow is a wood industry focused platform. Our team received your request and is already working on your company verification.',
        detailsAccount:
          'Once your account has been verified, we will contact you to let you know. Don’t worry, we’ll verify your company details.',
        logout: 'Log out',
        contacUs: 'Contact us!',

        preferenceSave: 'Preferences added successfully!',
        test: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        passwordChanged: 'Password successfully changed',
        requestOpen: 'Sum of placed orders that have not yet been delivered to the port of destination.',
        m3buy: 'Purchased orders volume SUM (m³).',
        m3shipped: 'Shipped orders volume SUM (m³).',
        m3price:
          'M³ purchased average price in US dollars (USD) = total shipments (amount) divided by the total of m³ purchased orders.',
        openSupplier:
          'Total amount (sum) of orders made by the customers that have not yet been delivered to the port of destination.',
        m3shippedSupplier: 'Volume in m³ of all orders in your history.',
        valueTotal: 'Volume SUM (in m³) of all shipped orders.',
        valueMid: 'Average price per m3 of all orders in your history.',
      },
      pluralize: {
        product_one: 'item',
        product_other: '{{count}} pieces',
      },

      exceptions: {
        cnpjAlready: 'This customer is already registered',
        numberAlreadyExists: 'Number already registered.',
        purchaseOrderNotFound: 'Purchase order not found',
        errorDocument: 'Oops, something went wrong while downloading.',
        passwordNotEqual: 'The passwords provided do not match',
        invalidEmail: 'Invalid email! Please enter again.',
        emailAlready: 'E-mail already exists, please login',
        minWidthAndHeigth: 'Error! Invalid height and/or width. Minimum supported format 1280x720.',
        maxSizeImage: 'Max file size 1MB',
        invalidFounded: 'The foundation year must be greater than 1900',
        citiesDestination: 'Cities with the same destination',
        portsDestination: 'Ports with the same destination',
        productsBusiness: 'There are no products related to this business.',
        requiredField: 'Required field',
        invalidEmailMustCharacters: 'E-mail already registered',
        invalidPhoneNumber: 'Phone number is not valid',
        invalidCompanyName: 'Max 255 characters',
        invalidFile: 'it was removed because it was bigger than 240kbs',
        invalidFormatFile: 'Invalid format file',
        invalidPost: 'Company email not informed',
        invalidRequest: 'To send an e-mail, inform the order',
        invalidDescription: 'Invalid description, enter at least 2 characters',
        invalidPhone: 'Telephone number must contain at least 7 characters.',
        withoutProducts: 'It is not possible to remove all products from the business.',
      },
      labels: {
        paymentBeneficiaryName: 'Beneficiary name',
        paymentBeneficiaryAccount: 'Beneficiary account',
        paymentFinalBeneficiaryBank: 'Final beneficiary bank',
        paymentSWIFTFinalBank: 'SWIFT final bank',
        paymentIntermediaryBank: 'Intermediary bank',
        paymentSWIFTIntermediaryBank: 'SWIFT intermediary bank',
        paymentIBANCode: 'IBAN Code',
        paymentAdditionalInformation: 'ABA Code',

        selectCompany: 'Select the company',
        selectCompanyImporter: 'Select the importing company to upload the registered address',
        notInformed: 'Uninformed',

        nameAndLastName: 'Name and surname',
        sendNameAndLastName: 'Enter contact full name',
        emailContact: 'E-mail adress',
        sendEmailContact: 'Enter the e-mail adress',
        phoneContact: 'Phone number ',
        sendPhoneContact: 'Enter the phone number',
        nameAndLastNameContact: 'Main contact name and surname',
        sendNameAndLastNameContact: 'Enter contact full name',

        cnpj: 'Taxpayer Identification Number',
        sendCnpj: 'Enter the number',
        sendCompanyName: 'Enter the company name',
        companyName: 'Company name',
        fullAddress: 'Full adress',
        sendFullAddress: 'Ex: street, avenue, number...',

        notApprove: 'Unapproved',
        approve: 'Approved',

        selectOneProduct: 'Please select at least 1 product below.',

        productRelation: 'Related products*',

        validDocument: 'Validity of the document',

        selectTypeDocument: 'Select document type',

        openDoc: 'Open documentation',
        exporterAddress: 'Exporter address',
        exporterSecondAddress: 'Supplier address 2',
        sendExporterAddres: 'Type supplier address here',
        exporterName: 'Supplier',
        sendExporterName: 'Type supplier name here',

        exporterSecondName: 'Supplier 2',
        sendExporterSecondName: 'Type supplier 2 name of this step here ',

        importerName: 'Importer name',
        sendImporterName: 'Type importer name of this step here',

        importerAddress: 'Importer address',
        sendImporterAddres: 'Type importer address here',

        selectCertificate: 'Select the certificate for this step',
        cod_certificate: 'Type the certificate code',

        textM3: 'Unit Price x Total Volume',
        textPieces: 'Unit Price x Quantity of Pieces',

        the: 'The ',
        available: ' is available for reading and acceptance.',
        acceptAndReading: 'I have read and accept',

        accepted: 'I read and agree with it',
        offers: 'Special offers based on your preferences',
        foundByQuery:
          'There are {{products}} products and {{shippers}} suppliers based on your preferences. Check them out and ',
        sendQuote: 'request quotes ',
        now: 'now!',
        dateShipment: 'boarding date',
        prodWidth: 'Product width',
        prodLength: 'Product length',
        prodThickness: 'Product thickness',
        specie: 'Species',
        sendSpecie: 'Write product species',
        volumeM3: 'M³',
        pieces: 'Pieces',
        nextShip: 'Next shipments',
        notNextShip: 'There are no upcoming shipments planned.',
        notNextInfo: 'No information available.',

        po: 'Purchase order',

        noOptions: 'There are no more options',

        options: 'Options',

        information: 'Information',

        catalog: 'Catalog',

        acabaments: 'Finishes',
        layersOfProduct: 'Enter one or more layers',
        photoProduct: 'Product photo upload (size limit 240kbs, accepted formats, JPG and PNG)',

        productIdentification: 'Product identification',
        selectProductSupplier: 'Select product supplier',
        selectProductCategory: 'Select product category',
        enterProductName: 'Enter product name',
        enterProductNameInEnglish: 'Enter product name in English',
        productNameInPortuguese: 'Product name (in Portuguese)',
        productNameInEnglish: 'Product name (in English)',
        otherLanguageIsRequired: 'When filling out the description in one language, the other becomes mandatory.',
        internalNote: 'Internal note',
        layerNote: 'Note about layers',
        addInternalNote: 'Add an internal note about the product (max. 160 characters)',
        addLayerNote: "Add a note about the product's layers (max. 160 characters)",

        descriptionPtBr: 'Description (in Portuguese)',
        enterDescriptionPtBr: 'Add a description for the product (max. 160 characters)',
        description: 'Description (in English)',
        enterDescription: 'Add a description for the product, in English (max. 160 characters)',
        of: 'Of',
        until: 'Until',
        dimensions: 'Product dimensions',
        selectMetric: 'Select measurement',
        thickness: 'Thicknesses',
        sendThickness: 'Enter one or more thicknesses',
        onlyThickness: 'Thickness',
        sendOnlyThickness: 'Enter a thickness',
        typeMetricThickness: 'Thickness - measurement type',
        width: 'Widths',
        sendWidth: 'Enter one or more widths',
        onlyWidth: 'Width',
        sendOnlyWidth: 'Enter a width',
        typeMetricWidth: 'Width - measurement type*',
        length: 'Lengths',
        sendLength: 'Enter one or more lengths',
        onlyLength: 'Length',
        sendOnlyLength: 'Enter a length',
        typeMetricLength: 'Length - measurement type*',
        genericMessageValues: 'The added values cannot overlap.',
        genericErrorTag: 'Add only numbers, with a maximum of 20 tags in the field.',
        genericErrorTagNoLimit: 'Add only numbers in the field.',
        touchEnter: 'Use the Enter key to add more measure options.',

        labelTypeProduct: 'Category',

        notInformated: 'Uninformed',
        labelFinishies: 'Finishes',

        labelNotDocuments: 'There are no documents for this request',
        labelNotFollowUp: 'There was no follow-up for this request.',
        labelInspectionNotPerformed: 'There is no inspection for that request.',
        labelPendingInspection: 'Pending Inspection: Order awaiting inspection submission.',
        labelFirstQuestion: '1. Product thickness',
        labelSecondQuestion: '2. Product width',
        labelThirdQuestion: '3. Product lenght',
        labelFourthQuestion: '4. Product face',
        labelFifthQuestion: '5. Products ready to ship pictures',
        labelSixthQuestion: '6. Pallets pictures',
        labelInspectDate: 'INSPECTION DATE',
        labelCatalogoDeProdutos: 'Status',
        labelUploadPhotoProduct: 'Upload product image',
        labelNomeEmpresa: 'Company name*',
        labelFundadaEm: 'Founded in*',
        labelHistoriaDaEmpresa: 'Company story - in portuguese*',
        labelDescrevaHistoria: 'Describe company’s history in portuguese (1250 characters max)',
        labelAtuacaoEmpresa: 'Market - in portuguese*',
        labelDescrevaAtuacao:
          'Describe the products types or market that the company operates, in portuguese (1250 characters max)',
        labelCertificados: 'Certificates (optional)',
        labelSelecioneCertificados: 'Select company certificates',
        labelImagemPerfil: 'Company profile picture',
        labelFotosEmpresa: 'Company pictures (4 img max)',
        labelDescriptionImageProfile: 'Upload your profile picture. We suggest using your company logo.',
        labelDescriptionImages: "Upload extra imagens for buyers to see. They'll be added to your profile description.",

        labelHistoriaEn: 'Company story - in english (optional)',
        labelDescrevaHistoriaEn: 'Describe company’s history in english (1250 characters max)',
        labelAtuacaoEmpresaEn: 'Market - in english (optional)',
        labelDescrevaAtuacaoEn:
          'Describe the products types or market that the company operates, in english (1250 characters max)',

        labelPerfilDaEmpresa: 'Profile ',
        labelCriacao: 'Profile setup',
        labelPerfil: 'Profile',
        labelEditarPerfil: 'Edit Profile',
        labelPerfilPublicado: 'Your profile has been published!',
        labelMessagePerfilPublicado: 'Congratulations, your profile has been published on WoodFlow.',
        labelPerfilRevisao: 'Your profile has been requested for review',
        labelPreencherPerfil: 'Thank you for submitted your profile.',
        labelPublish: 'At this time, it is under review by the WoodFlow team, and will soon be published.',
        labelCompleteProfile: 'Set up your profile for buyers to see',
        labelVitrine: 'WoodFlow is the largest showcase for Brazilian wood manufacturers.',
        labelProfileImporter:
          'By completing your profile, your business will be seen by buyers from all over the world.',
        labelNewPhoto: 'Upload another photo',
        labelsEditPhoto: 'Change image',
        labelNotApply: 'Not applicable',
        labelProductNotFound: 'No products found',
        labelSellerNotFound: 'No suppliers found',
        labelCustomerNotFound: 'No clients found',
        labelDescCustomerNotFound: 'No client matched your criteria.',
        labelDescProductNotFound: 'No product matched your criteria.  Try a different search.',
        labelDescSellerNotFound: 'No supplier matched your criteria.  Try a different search.',
        labelAllProducts: 'See all products',
        labelRequestQuote: 'Request a quote',
        labelSearchName: 'Search name',
        labelPageNotFound: 'Oops, page not found',
        labelPageNotExists: 'This page does not exist or cannot be found',
        labelQuoteSucess: 'Quote created with success!',
        labelTeamWill: 'Our team will be looking for the best offers and will soon get in touch with you!',
        labelShowMyQuotes: 'Show me my quotes',
        labelSelectProduct: 'Select product type',
        labelEspecify: 'Specify which',
        labelSelectUnitM: 'Select the unit of measure',
        labelWidth: 'Width (mm)*',
        labelEspessura: 'Thickness (mm)',
        labelLength: 'Length (mm)*',
        labelEspecifyProd: 'Specify volume size',
        labelComentsQuote: 'Your message here.',
        labelSendNewQuote: 'Send',
        labelReturn: 'Back',
        labelNewQuote: 'New quote request',
        labelTextNewQuote:
          "You're about to request a quote for {{company}}.  If you'd like to change the company, remember to select the right company on the menu.",
        labelSpecify: 'Specify which',
        labelUnitCrud: 'Unit of measure',
        labelDimensionsCrud: 'Product dimentions',
        labelComents: 'Comments or additional info',
        labelQuoteWork: 'How quotation works:',
        labelSendQuote: 'Send your cote',
        labelTeamReiceve: 'Our team receive your quotation',
        labelBestOffers: 'We find the best offers',
        labelCallYou: 'We call you',
        labelTextQuoteInformative: 'All is done as fast as we can! No more waiting. And the best part, it is free!',
        labelStatusFilter: 'Filter by Status',
        labelSelectCountry: 'Select Country',
        labelRedirectToLogin: 'Login >',
        labelOr: 'Or',
        labelsNotGetEmail: 'Did not get the e-mail?',
        labelEmailSendFor: 'An e-mail has been sent to:',
        labelAccessEmail: 'Access your e-mail',
        labelAreYouProducer: 'Already have an account?',
        labelEnterHere: 'Sign in.',
        labelFirstName: 'First name*',
        labelLastName: 'Last name*',
        labelCompanyName: 'Company name*',
        labelContryName: 'Country*',
        labelAcceptTerms: 'By creating your account you are agreeing to our',
        labelPrivacy: 'Privacy Policies',
        labelMeetWoodflow: 'Meet WoodFlow: ',
        labelSelectedProducers: 'Pre-selected producers',
        labelMultipleQuotes: 'Multiple quotes',
        labelTransparentNegotiation: 'Transparent negotiation',
        labelOnlineTracking: 'On-line tracking',
        labelImportTheLumber: 'As a member, you will:',
        labelBrazilianLumber: 'the best wood platform',
        labelContactWhatsApp: 'Contact WoodFlow via WhatsApp',
        labelsReSendEmail: 'Resend is e-mail',
        labelNotAccount: 'Don’t have an account?',
        labelDescription: 'Description',
        labelResponsible: 'Responsible',
        labelStatus: 'Status',
        labelExporter: 'Shipper',
        labelContact: 'Contact',
        labelImporter: 'Customer',
        labelRequest: 'Request',
        labelInvoice: 'Invoice',
        labelComments: 'Notes',
        labelIncoterm: 'Incoterm',
        labelBooking: 'Booking',
        labelOrigin: 'Place of origin',
        labelFinalDestination: 'Final destination',
        labelPortOfShipment: 'Port of embarkation',
        labelPortDestination: 'Port of destination',
        labelPortOfLoading: 'Port of Loading',
        labelPortOfDischarge: 'Port of discharge',
        labelContainerType: 'Container type',
        labelNumOfContainer: 'Container quantity',
        labelM3: 'M3',
        labelTransport: 'Carrier',
        labelNameShip: "Vessel's Name",
        labelForgotPassword: 'Forgot password?',
        labelAlterPassword: 'Alter password',
        labelCopyright: 'WoodFlow. All rights reserved',
        labelBusinessDashboard: 'Track your orders',
        labelFilter: 'Filters',
        labelFilterClear: 'Clear Filters',
        labelSearchProducts: 'Search products',
        labelDimensions: 'Dimensions',
        labelQuantityOfPallets: 'Quantity of pallet',
        labelPiecesOfPallets: 'Pieces per pallet',
        labelVolumePerPallet: 'Volume (m³) per pallet',
        labelQuantityOfPieces: 'Total quantity of pieces',
        labelTotalVolume: 'Total volume (m³)',
        labelUnityPrice: 'Unit Price (USD$)',
        labelTotalAmount: 'Total Amount (USD$)',
        labelExpectedDate: 'Expected',
        labelCurrentDate: 'Current',
        labelRealizedDate: 'Realized',
        labelDate: 'Date',
        labelCargoReady: 'Cargo ready to load',
        labelArivalAtOrigin: 'Arrival at origin port',
        labelLoading: 'Loading',
        labelArrivalAtDestination: 'Arrival at destination port',
        labelLoadingAtDestination: 'Loading at destination port',
        labelDelivery: 'Delivery',
        labelPayment: 'Payment',
        labelPaymentMethod: 'Payment Method',
        labelStatusPayment: 'Status payment',
        labelTotalAmountPayment: 'Total amount',
        labelAdvenced: 'Advanced',
        labelBalance: 'Balance',
        labelBoughtBy: 'Bought by',
        labelSoldBy: 'Sold by',
        labelShipment: 'Shipment',
        labelEstimated: 'estimated',
        labelToBeInformed: 'to be informed',
        labelEdit: 'Edit',
        labelDetail: 'Detail',
        labelThereAreNoDocuments: 'does not have documents...',
        labelQuality: 'Quality',
        labelGlue: 'Glue',
        labelCertificates: 'Certificate',
        labelItems: 'Items',
        labelUnid: 'Price per',
        labelUnit: '$ Unit',
        labelM3Total: 'M³ total',
        labelHide: 'Hide',
        labelNoContent: 'No records exist...',
        labelUndefined: 'to be informed',
        labelShippingAgent: 'Shipping agent',
        labelVessel: 'Vessel',
        labelToBeConfirmed: 'To be confirmed',

        labelProductCat: 'Product visible in the Product Catalog',
        labelStatusProfile: 'Profile status',
        labelActive: 'Active',
        labelInactive: 'Inactive',
        labelPhotoProduct: 'Product photo (Limit size 240kbs, accepted formats, JPG and PNG)',
        labelRemoveImage: 'Remove image',
        labelAddImg: 'Add image',
        labelDetailsQuote: 'Quote request details',
        labelRequester: 'Requester',
        labelProduct: 'Product',
        labelMetrics: 'Measurements',
        labelDetails: 'Comments',
        labelJustify: 'Justification',
        labelDetailStatus: 'Status details',
        labelEditQuote: 'Edit quote',
        labelsM3OrPieces: 'M³ or pieces',
        labelsSendEmails: 'Select the e-mails you want to notify',
        labelsCreateSuccess: 'Successfully created',
        labelsCompanies: 'Search by shipper',
        labelsDocuments: 'Choose document',
        labelsAdress: 'Address',
        labelsSearchAdress: 'Type to search',
        labelsRoles: 'Roles',
        labelsCustomer: 'Company',
        labelsPhoneNumber: 'Telephone (optional)',
        labelsComercialContact: 'Business contact',
        labelsComercialEmail: 'Business email',
        labelsProductionContact: 'Production contact',
        labelsProductionEmail: 'Production email',
        labelsLogisticContact: 'Logistical contact',
        labelsLogisticEmail: 'Logistics email',
        labelsDespachContact: 'Forwarding contact',
        labelsDespachEmail: 'Dispatcher email',
        labelDocumentType: 'Document type',
        labelUpdateDate: 'Update',
        labelShipmentForecast: 'New expected departure date (ETD)',
        labelArrivalForecast: 'New estimated arrival date (ETA)',
        labelNoRecords: 'No records exist...',
        labelsCompaniesCrud: 'Company',
        labelsNotifyCystomer: 'Notify customer about new update',
        labelFinish: 'Finished',
        labelSpecieProduct: 'Wood species',
        labelPurposeProduct: 'Goal',
        labelQualityProduct: 'Quality',
        labelGlueProduct: 'Glue',
        labelCertificateProduct: 'Certificate',
        labelLengthProduct: 'Length',
        labelWidthProduct: 'Width',
        labelThicknessProduct: 'Thickness',
        labelLayersProduct: 'Layers',
        labelDescriptionPTBRProduct: 'Product’s name (portuguese)',
        labelDescriptionProduct: 'Product’s name (english)',
        labelCompaniesProduct: 'Link product to companies',
        labelPublicProfile: 'Public profile',
        labelFounded: 'Since',
        labelHistoryPT: 'Company history - PT',
        labelActingPT: 'Company performance - PT',
        labelActingEN: 'Company performance - EN',
        labelHistoryEN: 'Company history - EN',
        labelLinkMovie: 'Link to page video (optional)',
        labelLinkTour: 'Link to company tour (optional)',
        labelImageProfile: 'Profile picture',
        labelImageUpload: 'Upload images',

        labelDescriptionEditStatus1: 'In edit',
        labelDescriptionEditStatus2: 'Awaiting publication',
        labelDescriptionEditStatus3: 'Published',

        labelFilterStatus: 'Filter by profile status',

        labelDescriptionEditEdit: 'Your profile is being edited',
        labelDescriptionEditChange:
          'Change your information that was already submitted or published in WoodFlow. You can make changes whenever',
        labelDescriptionEditPublish: 'necessary. To submit the changes to be published, click Submit.',

        labelStatusDate: 'New date of status',

        sendPortName: 'Enter the port name',
        portName: 'Port name',
        cdCountry: 'Country code',
        sendCdCountry: 'Enter the country code',
        country: 'Country',
        sendCountry: 'Enter the country',
        labelPortNotFound: 'No port found.',
        labelDescPortNotFound: 'No port matched your criteria. Try a different search.',
      },
      switch: {
        labelSendEmail: 'Send e-mail',
        labelFinished: 'Finished',
      },

      radio: {
        titlePayment: 'Advanced payment',
        yes: 'Yes',
        no: 'No',
      },
      placeholders: {
        holderPaymentAdditionalInfo: 'Add other relevant information about banking information here',
        holderAcabaments: 'Select one or more finishes for the product',
        holderQuality: 'Select one or more qualities for the product',
        holderSpecie: 'Select one or more species for the product',

        holderFilterProducts: 'Search by product name, type or species',
        holderResponsibleOrder: 'Responsible for purchase',
        holderResponsible: 'Responsible',
        holderStatus: 'Status',
        holderExporter: 'Shipper',
        holderContact: 'Contact',
        holderImporter: 'Customer',
        holderRequest: 'Order',
        holderInvoice: 'Invoice',
        holderComments: 'Notes',
        holderPassword: 'Password',
        holderNewPassword: 'New Password',
        holderConfirmPassword: 'Repeat New Password',
        holderConfirmReicevedPassword: 'Password received by e-mail',
        holderEmail: 'E-mail',
        holderBooking: 'Booking',
        holderOrigin: 'place of origin',
        holderFinalDestination: 'Final destination',
        holderPortOfShipment: 'port of embarkation',
        holderContainerType: 'container type',
        holderPortDestination: 'destination port',
        holderPort: "Search for port's name",
        holderNumOfContainer: 'container quantity',
        holderM3: 'M3',
        holderTransport: 'carrier',
        holderNameShip: "Vessel's Name",
        holderQueryFilter: 'Search...',

        holderFilterUsers: 'Search by first name, last name or user e-mail',
        holderCompanies: 'Companies',
        holderTypeProduct: 'Search for a product type',
        holderSpecieProduct: 'Search for a kind of product',
        holderPurposeProduct: 'Look for a product purpose',
        holderQualityProduct: 'Look for a product quality',
        holderGlueProduct: 'Look for a product glue',
        holderCertificate: 'Select one or more certificates',
        holderDate: 'Select the date',
        holderNewUser: 'Name',
        holderNameCompany: 'Search by company name',
        holderSearchDesktop: 'Search my computer',
        holderFormatsAccepted: 'Accepted formats: .xlsx, jpeg, PDF, png',
        holderProfile: 'Profile',
        holderCodCertificate: 'Type the certificate code',
        holderFilterClients: 'Search by client name',
      },
      buttons: {
        docsEmit: 'Documents to be issued',
        editInformations: 'Edit details',

        newClient: 'New client',
        sendInvite: 'Send invitation',

        newPort: 'New port',

        shareAccess: 'Invite to access',

        remove: 'Remove',
        startNow: 'Start now! >',
        newProduct: 'New product',
        onlySave: 'Save',
        sendToSubmit: 'Submit',
        saveDraft: 'Save as a draft',
        save: 'Register',
        login: 'Login',
        send: 'Send',
        forgot: 'Send',
        search: 'Search',
        add: 'Add',
        columns: 'Columns',
        confirmPassword: 'Confirm password',
        goToHome: 'Go to home',
        saveAndSend: 'Save and send',
        saveCustomer: 'Save company',
        editCustomer: 'Edit company',
        edit: 'Edit',
        newRegister: 'New register',
        delete: 'Delete',
        cancel: 'Cancel',
        continue: 'Continue',
        addProduct: 'Add product',
        addDocument: 'Add document',
        saveAndNotify: 'Save and notify by e-mail',
        newUpdate: 'New Update',
        newFollow: 'New Follow Up',
        att: 'Update',
        saveAndAddDocuments: 'Save and add documents',
        saveAndGoToStep: 'Save and go to Step',
        editDetails: 'Edit details',
        downloadSelecteds: 'Download',
      },
      sideBar: {
        home: 'Home',
        register: 'New business',
      },
      pagination: {
        next: 'Next',
        previous: 'Previous',
        page: 'page',
        of: 'of',
        ordersPerPage: 'Sales per page',
        productsPerPage: 'Products per page',
        quotesPerPage: 'Quotes per page',
        clientsPerPage: 'Clients per page',
        documentsPerPage: 'Documents per page',
        followUpsPerPage: 'Follow ups per page',
      },
      table: {
        piecePallet: 'Pallet parts',

        country: 'Country',

        phone: 'Phone',

        contact: 'Contact name',
        docType: 'Document type',
        expDate: 'expirY date',
        provider: 'Provider',

        costFreight: 'Cost of freight',
        unitPrice: 'Unit price',

        dateOfCreate: 'Creation date',
        image: 'Image',
        typeProduct: 'product type',
        measure: 'Measure',
        quoteAccepted: 'Accepted Proposals',
        file: 'File',
        mesures: 'mesures',
        unit: 'unit',
        lastUpdated: 'Last Updated On',
        product: 'Product',
        qty: 'Qty',
        unity: 'Unit',
        price: 'Price',
        notes: 'Notes',
        date: 'Date',
        updatedDate: 'Update date',
        documentName: 'Document name',
        documentType: 'Document type',
        downloadFile: 'Download file',
        paymentMethod: 'Payment method',
        paymentStatus: 'Payment status',
        advanced: 'Advanced',
        totalAmount: 'Total amount',
        balance: 'Balance',
        productName: 'Product name',
        supplier: 'Supplier',
        customer: 'Customer',
        orderDate: 'Order Date',
        contract: 'Contract',
        width: 'Width mm',
        length: 'Length mm',
        thickness: 'Thickness mm',
        layers: 'Layers mm',
        grade: 'Grade',
        certificates: 'Certificates',
        pallets: 'Pallets',
        qtdPecaPallet: 'Pieces',
        panels_per_crate: 'Panels per crate',
        productPrice: 'Product price',
        carrier: 'Carrier',
        vessel: 'Vessel',
        viewNote: 'view note',
        glue: 'Glue',

        statsAccess: 'Access status',

        typeDocument: 'Document type',
        nameFile: 'File name',
        valid: 'Expiry date',
        produtos: 'Products',

        buyer: 'Importer',
        shipperPrev: 'Boarding forecast',
        description: 'Description',

        importer: 'Importer',
        order: 'Purchase order',
        step: 'Step',
        shipper: 'Shipper',
        origin: 'Origin',
        locale: 'Destiny',

        measurementProduct: 'Product measurements',
        nameCompany: 'Company name',
        nameUser: "Applicant's name",
        dateCreate: 'Creation date',
        fileName: 'File name',
        type: 'Type',
        responsible: 'Responsible',
        document: 'Document',
        more: 'View more',
        att: 'Updated at',
        currentStatus: 'Current status',
        shipmentForecast: 'Shipment forecast',
        arrivalForecast: 'Arrival forecast',
        acting: 'Acting',
        company: 'Company',
        nameClient: 'Customer name',
        customerClient: "Customer's country",
        contactComercial: 'Comercial contact',
        actions: 'Actions',
        situation: 'Situation',
        port: 'Port',
        codeCountry: 'Country code',
      },
      modal: {
        removeFollow: 'You are about to delete the update. When deleting, the action cannot be undone.',
        modalTitleProducts: 'Products',
        modalTitleUploadDocument: 'Upload document',
        modalTitleActions: 'Actions',
        newProduct: 'New product',
        editCompany: 'Edit company',
        editProduct: 'Edit product',
        createCustomer: 'Create customer',
        confirmDelete: 'You are about to delete a deal, are you sure about this action?',
        confirmDeleteCompany: 'You are about to delete a company, are you sure about this action?',
        confirmDeleteProduct: 'You are about to delete a product, are you sure about this action?',
        confirmDeleteDocument: 'You are about to delete a document, are you sure about this action?',
        confirmDeletePhoto: 'You are about to delete a photo, are you sure about this action?',

        messsageFirstAcess:
          'Set your preferences and get the best WoodFlow offers. What products are you interested in?',

        welcomeToWoodflow: 'Welcome to woodflow!',
        btnNotInteressed: 'Not interested',
        btnSavePreferences: 'Save preferences',
        textPreferencesOffers: 'Set your preferences and get the best WoodFlow offers!',
        addPreferences: 'Set preferences',

        removeProductTitle: 'Delete product',
        removeProductSubTitle: 'Are you sure you want to delete this product?',
        removeProductDescription: 'After deleting, you will not be able to recover this product.',

        removeMultiProductTitle: 'Delete products',
        removeMultiProductSubTitle: 'Are you sure you want to delete this products?',
        removeMultiProductDescription: 'After deleting, you will not be able to recover this products.',
      },
      uploadFiles: {
        uploadFile: 'Upload a file',
        awbDocuments: 'Awb Original Documents',
        shipmentExperience: 'Shipment knowledge',
        certificateOrigin: 'Certificate of origin',
        containerRom: 'Romanian per  container',
        contract: 'Contract',
        cte: 'cte',
        draftBI: 'Draft BI',
        expressRealease: 'Express Release',
        certifcateFum: 'Fumigation certificate',
        certifcateHit: 'Hit certificate',
        certifcateInspecion: 'Inspection certificate',
        certifcateSecurity: 'Insurance certificate',
        invoice: 'Invoice',
        creditCard: 'Credit card',
        fiscalNote: 'Invoice',
        romanian: 'Romanian',
        photos: 'Photos',
        phytosanitary: 'Phytosanitary',
        proformaInvoice: 'Proforma invoice',
        get: 'Request',
        signedProformaInvoice: 'Signed pro forma invoice',
        warehouseLetter: 'Warehouse letter',
        downloadSuccess: 'Downloaded successfully',
        doctoSelected: 'Selected documents',
      },
      response: {
        saveSuccess: 'Information successfully saved.',

        inviteSuccess: 'Invitation(s) sent successfully',

        emailError: 'This email is not registered, please check and try again!',

        editDraft: 'Draft saved sucessfully',
        editAnalise: 'Your submission has been sent',
        editSuccess: 'Edited successfully',
        postSuccess: 'Registered successfully',
        deleteSuccess: 'Successfully deleted',
        linkCopy: 'Copied link',
        emailSendSucess: 'An email was sent, access the email to have the right to register a new password!',
        emailNotExists: 'E-mail does not exist',
        successduplicated: 'Successfull duplicated',
      },
      emptyState: {
        shipmentSchedule: 'Once you close a deal, your order(s) will be shown here.',
        notFoundOrders: 'No orders yet.',
        notFound: 'No results found',
        noProductsToEdit: 'There are no products added.',
        noProductsToSaveBusiness:
          'There are no products added. To save the deal, it is mandatory to add at least one product.',
      },
      newSale: {
        shippingTitle: 'Shipping Information',
        shipName: 'Vessel Name',
        estimatedArrival: 'Estimated Arrival',
        buttonAllDetails: 'See All Details',
        buttonGenerateProforma: 'Generate Proforma',
        copyBooking: 'Booking copied to clipboard.',
        copyInvoice: 'Invoice copied to clipboard.',
        product: {
          name: 'Name',
          length: 'Length MM',
          width: 'Width MM',
          thickness: 'Thickness MM',
          total: 'Total m²',
          price: 'Unit Price',
          seeMore: 'see more',
        },
        container: {
          containerType: 'Container type',
          qtyOfContainers: 'Qty of containers',
          totalShipped: 'Total m³',
        },
      },
    },
  },
};
