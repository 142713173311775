import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Dropdown, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import BR from '../../statics/country/brazil.svg';
import USA from '../../statics/country/usa.svg';
import UsersService from '../../services/users.service';

import './styles.scss';

interface IProp {
  classes?: string;
  isLogged?: boolean;
}

const SelectLanguage = ({ classes, isLogged }: IProp): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const location: any = useLocation();
  const history = useHistory();

  async function handleChangeLang(lang: string): Promise<void> {
    i18n.changeLanguage(lang);

    if (isLogged) {
      await UsersService.setLanguage(lang === 'en-US' ? 'en' : 'pt-BR');
    }
  }

  return (
    <Dropdown key="test-language" className={`${classes} selectLanguage`}>
      <Dropdown.Toggle id="dropdown-basic">
        <Image src={t('language') === 'USA' ? USA : BR} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item className="d-flex align-items-center justify-content-center">
          <Button
            cy="btn-lg-ptbr-test"
            className="btnLanguage"
            type="button"
            onClick={() => {
              if (location?.pathname.includes('/add-product')) {
                history.go(0);
              }
              handleChangeLang('pt-BR');
            }}
          >
            <Image src={BR} />
          </Button>
        </Dropdown.Item>
        <Dropdown.Item className="d-flex align-items-center justify-content-center">
          <Button
            cy="btn-lg-en-test"
            className="btnLanguage"
            type="button"
            onClick={() => {
              if (location?.pathname.includes('/add-product')) {
                history.go(0);
              }
              handleChangeLang('en-US');
            }}
          >
            <Image src={USA} />
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

SelectLanguage.defaultProps = { classes: '' };
export default SelectLanguage;
