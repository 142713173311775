export const messages = {
  pt: {
    translations: {
      language: 'BR',
      selectTranslation: {
        translate: 'Selecione o idioma',
      },
      format: {
        date: 'dd/MMM/yyyy',
      },
      tags: {
        titleRegister: 'Registrar',
      },
      steps: {
        forest: 'Etapa 1: Floresta',
        processing: 'Etapa 2: Processamento',
        export: 'Etapa 3: Exportação',
        details: 'Detalhes',
        exporter: 'Exportador',
        address: 'Endereço do exportador',
        importer: 'Importador',
        provider: 'Fornecedor',
        addressImporter: 'Endereço do importador',
      },
      business: {
        selectFreit: 'Selecione o frete',
        freit: 'Frete',
        noPrevision: 'Sem previsão',
        statsActual: 'Status atual',
        messageExplainStats: 'O status representa que a carga está presente no seu destino final.',
        etdExplain: 'ETD - Embarque',
        datePrev: 'Data prevista',
        etaShipper: 'ETA - Entrega',
        historiesActualize: 'Histórico de atualizações',
        typeFollow: 'Tipo de follow up',
        removeFollow: 'Remover follow up',
        historyAtt: 'Histórico de atualizações',

        obsHolder: 'Adicione aqui informações adicionais (máx. 255 caracteres)',
        newStatus: 'Novo status',
        obsOptional: 'Observações (opcional)',
        typeFollowUp: 'Tipo de Follow Up',
        generateProforma: 'Gerar Proforma',
        dateEstimatedBusiness: 'Data estimada de embarque',
        tolerancem3: 'Tolerância m³',
        sendTolerancem3: 'Digite a tolerância em m³',
        totalM3: 'Total m³ embarcado',
        consignee: 'Consignee (opcional)',
        notify: 'Notify 1 (opcional)',
        notify2: 'Notify 2 (opcional)',
        docsEmitProforma: 'Documentos a serem emitidos - para constar na Pro forma',
        sendDocsEmitProforma:
          'Adicione aqui os documentos a serem emitidos. Ex: Invoice, Packing List, BL, certificados...',
        obsProforma: 'Observações para constarem na Pro forma',
        infoSale: 'Informações do pedido',
        docsEmit: 'Documentos a serem emitidos',
        information: 'Informação',
        backToForm: 'Voltar para formulário',
        backToBusiness: 'Voltar para pedidos',
        sendTo: 'Enviar para',
        addEmail: 'Adicionar e-mail',
        pleaseAddFourEmails: 'Você pode adicionar até 4 e-mails por pedido.',
        infoImporterEmail: 'O importador com o e-mail cadastrado poderá acompanhar os detalhes do pedido.',
        businessMessageSupport: 'Os e-mails cadastrados poderão acompanhar as informações desse pedido. ',
        messageReallyDelete: 'Tem certeza que deseja excluir o negócio? ',
        confirmReallyDelete:
          'Após a exclusão, você perderá todas as informações cadastradas e deverá adicioná-las novamente.',
        sendTotalVolume: 'Digite o volume em m³',
        nameFile: 'Nome do arquivo',
        newBusiness: 'Novo pedido',
        editBusiness: ' Editar negócio',
        infoBusiness: 'Informações do negócio',
        responsible: 'Responsável pelo negócio',
        sendResponsible: 'Digite o nome do responsável pelo negócio',
        po: 'PO (ordem do pedido)',
        sendPo: 'Digite o código da ordem do pedido',
        importer: 'Importador*',
        sendImporter: 'Digite o nome do importador',
        contactImporter: 'Contato do importador',
        sendContactImporter: 'Adicione o contato do importador',
        obs: 'Observações',
        sendObs: 'Adicione aqui informações adicionais sobre o negócio (máx. 255 caracteres)',

        products: 'Produtos',
        selectProduct: 'Selecione a categoria do produto:',
        specie: 'Espécie*',
        selectSpecie: 'Selecione uma espécie para o produto',
        acabaments: 'Acabamento(s)',
        selectAcabaments: 'Selecione um ou mais acabamentos para o produto',
        qualities: 'Qualidade(s)',
        selectQualities: 'Selecione um ou mais qualidades para o produto',
        certificates: 'Certificado(s)',
        selectCertificates: 'Selecione um ou mais certificados para o produto',
        glue: 'Cola',
        selectGlue: 'Selecione a cola do produto',
        thickness: 'Espessura(s)',
        layers: 'Camada(s)',
        selectLayers: 'Selecione as camadas',
        width: 'Largura(s)',
        length: 'Comprimento(s)',
        holderMms: 'Unidade em mm',
        m3OrPieces: 'M³ ou peças',
        totalVolumem3: 'Total volume M³',
        qtdPieces: 'Quantidade de peças',
        sendQtdPieces: 'Digite o número de peças',
        qtdPalet: 'Quantidade de pallet',
        numberOfPallets: 'Digite o número de pallets',
        qtdPiecesPalet: 'Quantidade de peças por pallet',
        numberOfPiecesPallet: 'Digite a quantidade de peças por pallet',
        priceUnit: 'Preço unitário (USD$)',
        totalPrice: 'Preço total',
        removeProduct: 'Remover produto',
        removeDocumentEUDR: 'Remover documento',
        questionRemove: 'Tem certeza que deseja remover o produto dessa venda? ',
        questionRemoveDocument: 'Você está prestes a deletar um documento, você tem certeza dessa ação?',
        affirmativeRemove:
          ' Após excluir o produto, você perderá as informações cadastradas e deverá adicioná-las novamente.',
        quantity: 'Quantidade de pallets:',
        editProduct: 'Editar produto',
        paymentMethod: 'Forma de pagamento',
        sendPaymentMethod: 'Digite qual a forma de pagamento',
        totalValue: 'VALOR TOTAL',
        totalAmount: 'VALOR A RECEBER',
        valueAdiant: 'Valor antecipado',
        statusPayment: 'Status de pagamento',
        sendStatusPayment: 'Digite o status',
        origin: 'Local de origem',
        selectOrigin: 'Selecione qual o local de origem',
        final: 'Destino final',
        selectFinal: 'Selecione qual o destino final',
        port: 'Porto de embarque',
        selectPort: 'Selecione qual o porto de embarque',
        portDestine: 'Porto de destino',
        selectPortDestine: 'Selecione qual o porto de destino',
        selectInconterm: 'Selecionar o Incoterm',
        sendBooking: 'Digite o booking do pedido',
        sendInvoice: 'Digite o código do invoice',
        typeContainer: 'Tipo de container',
        selectTypeContainer: 'Selecione o tipo de container utilizado',
        qtdContainer: 'Quantidade de container',
        sendQtdContainer: 'Digite o nº de container',
        qtdM3: 'Quantidade em M³',
        sendQtdM3: 'Digite a quantidade em M³',
        nameShip: 'Nome do navio',
        sendNameShip: 'Digite o qual o nome do navio',
        transporter: 'Transportador',
        sendTransporter: 'Digite o nome do transportador',
        valueFreit: 'Valor do frete',

        infoBalance: 'Valor referente ao valor total - valor antecipado.',
        infoTotalAmount: 'Valor total referente a soma dos produtos adicionados.',

        provider: 'Exportador',
        duplicate: 'Duplicar',

        nameDescProForma: 'Descrição do produto',
        sendNameDescProForma: 'Digite a descrição do produto',
        nameInsurance: 'Seguro',
        sendInsurance: 'Digite o valor da seguradora',
        nameLastDateShipment: 'Última Data de embarque',
        sendLastDateShipment: 'Digite a última data de embarque',
        nameTranshipment: 'Transbordo',
        sendTranshipment: 'Digite o valor do Transbordo',
        namePartialShipments: 'Envios parciais',
        sendPartialShipments: 'Digite o valor para envios parciais',
        nameDynamicTitle: 'Título dinâmico',
        sendNameDynamicTitle: 'Digite o título',
        nameDynamicDescription: 'Descrição dinâmica',
        sendNameDynamicDescription: 'Digite a descrição',
      },
      headers: {
        businessDashboard: 'Entregas',
        goodMorning: 'Bom dia',
        goodAfternoon: 'Boa tarde',
        goodNight: 'Boa noite',
        createAccount: 'Criar conta',
        joinWoodflow: 'Junte-se ao WoodFlow.',
        newCreateAccount:
          'Solicitar e receber cotações, negociar preços e fechar negócios agora ficou mais fácil do que nunca.',
        sales: 'Pedidos',
        profile: 'Perfil',
        newDashboardSales: 'Últimos Pedidos',
        newDashboardBusinessDashboard: 'Últimas Entregas',
      },
      card: {
        title: 'Nós possuímos mais para oferecer!',
        action: 'Clique aqui',
        subtitle: 'e veja todos os nossos produtos!',
        viewAllProducts: 'ver todos os produtos',
      },
      footer: {
        labelBlog: 'Blog',
        labelContatct: 'Contato',
        labelPolicies: 'Políticas',
        labelPrivacyPolicy: 'Política de Privacidade',
        labelTermsOfUse: 'Termos de uso',
      },
      selects: {
        proforma: 'Proforma',
        operatingLicense: 'Licença de operação',
        fscCertified: 'Certificado FSC',
        other: 'Outros',
        propertyRegistration: 'Matrícula do imóvel',
        car: 'CAR',
        audits: 'Auditorias',
        forestAgreement: 'Contrato de compra e venda da floresta',
        invoiceLogs: 'Nota fiscal de compra das toras de madeira',
        invoice: 'Invoice',
        selectEutr: 'Selecionar produto referente aos documentos',
        open_balance: 'PAGAMENTO PARCIAL ',
        to_be_paid: 'A SER PAGO',
        paid: 'PAGO',
        open: 'EM ABERTO',
      },

      titles: {
        titleEmails: 'Selecione os e-mails para acompanhamento do pedido',
        infoBusiness: 'Informações do negócio',
        contactCard: 'Dados do contato principal da empresa',

        clientInfos: 'Dados da empresa',
        clients: 'Clientes',
        products: 'Produtos',
        supply: 'Cadeia de fornecimento',

        portinfos: 'Informações do porto',
        port: 'Portos',
        editPort: 'Editar porto',

        deleteBusiness: 'Excluir negócio',
        deleteDocument: 'Excluir documento',
        deleteFollow: 'Excluir Follow Up',
        verifyAccount: 'Boas-vindas a WoodFlow!',
        messageVerify: 'A plataforma WoodFlow está pronta para a utilização!',

        certificate: 'Certificado',
        exporter: 'Exportador',
        florest: 'Floresta',
        processing: 'Processamento',
        importing: 'Importação',
        eutr: 'Cadeia de fornecimento',
        inspection: 'Inspeção',
        newProduct: 'Cadastro de produto',
        editProduct: 'Detalhes do produto',
        supplier: 'Fornecedor',
        suppliers: 'Fornecedores',
        responseWoodflow: 'Justificativa',
        resume: 'Resumo',
        quoteDetail: 'Detalhes da cotação',
        app: 'Funcionários',
        businessData: 'Dados do negócio',
        businessShipData: 'Dados do embarque',
        businessProductData: 'Dados do produto',
        businessPlannerData: 'Agenda',
        businessPaymentData: 'Condições do pagamento',
        shippingInformation: 'Transporte',
        paymentsTerms: 'Pagamento',
        documents: 'Documentos',
        cargoDescription: 'Produtos',
        shippingDocuments: 'Documentos do embarque',
        actions: 'Actions',
        haveAccount: 'Tem uma conta? Entrar',
        quotes: 'Cotações',
        item1: 'Pesquisar produtos e conheça o fornecedor antes de fechar negócios',
        item2: 'Solicitar orçamentos, negocie e opte pelas melhores ofertas',
        item3: 'Rastrear seus pedidos de ponta a ponta em um só lugar',
        item4: 'Tenha informações valiosas rapidamente por meio de gráficos, gráficos e um painel completo',
      },
      messages: {
        accessNotAutorized: 'Acesso não autorizado',
        alertNoRemoveFollowUp: 'Esse Follow Up foi enviado por e-mail, portanto não pode mais ser excluído.',
        followRemoved: 'Follow Up removido com sucesso',
        informationDays: '{{days}} dia(s) para o embarque.',
        loadingPrevShipped: 'Aguardando previsão de embarque',
        loadingPrevArival: 'Aguardando previsão de chegada',
        docRemoved: 'Documento excluído com sucesso.',
        clickToSave: 'Clique em salvar, para que os produtos sejam relacionados na Cadeia de Fornecimento',
        removedSucess: 'Removido com sucesso',
        errorInspetor: 'Usuário inspetor não tem permissão para acessar a plataforma.',
        noMoreOptions: 'não há opções cadastradas',

        loadingVerify: 'Quase lá! Nós estamos verificando seu cadastro!',
        textExplication:
          'A WoodFlow é uma plataforma exclusivamente para profissionais de madeira. Nosso time recebeu seu cadastro e já está trabalhando na verificação da sua conta. ',
        detailsAccount:
          'Assim que sua conta for verificada, entraremos em contato. Não se preocupe, nós iremos verificar os detalhes da sua empresa.',
        logout: 'Sair',
        contacUs: 'Entre em contato!',

        preferenceSave: 'Preferências adicionadas com sucesso!',
        test: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt',
        passwordChanged: 'Senha alterada com sucesso!',
        requestOpen: 'Soma dos pedidos realizados que ainda não foram entregues ao porto de destino.',
        m3buy: 'Volume, em m³, de todos os pedidos comprados.',
        m3shipped: 'Volume, em m³, da soma de todos os pedidos com o status de embarcado.',
        m3price: 'Preço médio, em dólares ($), do total de embarques dividido pela soma dos m³ de pedidos comprados.',
        openSupplier: 'Soma dos pedidos realizados e que ainda não foram entregues ao porto de destino.',
        m3shippedSupplier: 'Volume em m³ de todos os pedidos no seu histórico.',
        valueTotal: 'Valor total: soma dos pedidos embarcados e/ou em trânsito.',
        valueMid: 'Preço médio por m3 de todos os pedidos no seu histórico.',
      },
      pluralize: {
        product_one: 'item',
        product_other: '{{count}} itens',
      },

      switch: {
        labelSendEmail: 'Enviar e-mail',
        labelFinished: 'Finalizado',
      },

      exceptions: {
        cnpjAlready: 'Este cliente já está cadastrado',
        numberAlreadyExists: 'Número já cadastrado.',
        purchaseOrderNotFound: 'Ordem do pedido não encontrada',
        errorDocument: 'Ops, algo de errado aconteceu ao efetuar o download',
        passwordNotEqual: 'As senhas informadas não condizem',
        invalidEmail: 'E-mail inválido! Por favor, digite novamente.',
        emailAlready: 'E-mail já existe, faça o login',
        minWidthAndHeigth: 'Erro! Altura e/ou largura inválidos. Formato mínimo aceito 1280x720.',
        maxSizeImage: 'Tamanho máximo 1MB',
        invalidFounded: 'O ano de fundação tem que ser maior que 1900',
        invalidEmailMustCharacters: 'E-mail já cadastrado',
        citiesDestination: 'Cidades com o mesmo destino',
        portsDestination: 'Portos com o mesmo destino',
        productsBusiness: 'Não existe produtos relacionados ao negocio.',
        requiredField: 'Campo obrigatório',
        invalidPhoneNumber: 'Telefone inválido',
        invalidCompanyName: 'Max 255 caracteres',
        invalidFile: 'foi removido por ser maior que 240kbs',
        invalidFormatFile: 'Formato de arquivo inválido',
        invalidPost: 'E-mail da empresa não informado',
        invalidRequest: 'Para enviar um e-mail, informe o pedido',
        invalidDescription: 'Descrição inválida, informe no minimo 2 caracteres',
        invalidPhone: 'Número de telefone deve conter pelo menos 7 caracteres.',
        withoutProducts: 'Não é possível remover todos os produtos do pedido.',
      },

      labels: {
        paymentBeneficiaryName: 'Beneficiário',
        paymentBeneficiaryAccount: 'Dados da conta do beneficiário',
        paymentFinalBeneficiaryBank: 'Banco final',
        paymentSWIFTFinalBank: 'SWIFT banco final',
        paymentIntermediaryBank: 'Banco intermediário',
        paymentSWIFTIntermediaryBank: 'SWIFT banco intermediário',
        paymentIBANCode: 'IBAN Code',
        paymentAdditionalInformation: 'ABA Code',

        selectCompany: 'Selecione a empresa',
        selectCompanyImporter: 'Selecione a empresa importadora para carregar o endereço cadastrado',
        notInformed: 'Não informado',

        nameAndLastName: 'Nome e sobrenome',
        sendNameAndLastName: 'Digite o nome completo do importador',
        emailContact: 'E-mail de contato',
        sendEmailContact: 'Digite o endereço de e-mail',
        phoneContact: 'Telefone de contato',
        sendPhoneContact: 'Digite o número para contato',
        nameAndLastNameContact: 'Nome e sobrenome de contato',
        sendNameAndLastNameContact: 'Digite o nome e sobrenome do contato',

        cnpj: 'Número de identificação fiscal',
        sendCnpj: 'Digite o número de id. fiscal. Ex: CNPJ',
        sendCompanyName: 'Digite o nome da empresa importadora',
        companyName: 'Nome da empresa',
        fullAddress: 'Endereço completo',
        sendFullAddress: 'Ex: rua/av, número...',

        notApprove: 'Não aprovado',
        approve: 'Aprovado',

        selectOneProduct: 'Selecione pelo menos 1 produto.',
        productRelation: 'Produtos relacionados ao documento*',
        validDocument: 'Validade do documento',
        selectTypeDocument: 'Selecione o tipo de documento',
        openDoc: 'Abrir documentação',
        exporterAddress: 'Endereço fornecedor',
        sendExporterAddres: 'Digite o endereço do fornecedor',

        exporterSecondAddress: 'Endereço fornecedor 2',
        sendExporterSecondAddres: 'Digite o endereço do exportador 2',

        importerAddress: 'Endereço do importador',
        sendImporterAddres: 'Digite o endereço do importador',
        exporterName: 'Exportador ',
        sendExporterName: 'Digite o fornecedor',

        exporterSecondName: 'Fornecedor 2',
        sendExporterSecondName: 'Digite o exportador 2',

        importerName: 'Nome do importador',
        sendImporterName: 'Digite o nome do importador para essa etapa',
        selectCertificate: 'Selecione o certificado para essa etapa',
        cod_certificate: 'Código do certificado',

        textM3: 'Preço Unitário x Total Volume',
        textPieces: 'Preço Unitário x Quantidade de Peças',

        the: 'A ',
        available: ' está disponível para leitura e concordância.',
        acceptAndReading: 'Li e aceito',

        accepted: 'Li e concordo com a',
        offers: 'Ofertas especiais com base em suas preferências',
        foundByQuery:
          'Encontramos {{products}} produtos e {{shippers}} fornecedores com base em suas preferências. Confira e ',
        sendQuote: 'solicite cotações ',
        now: 'agora!',
        dateShipment: 'Data de embarque',
        touchEnter: 'Use a tecla Enter para adicionar mais opções de medidas nos campos acima.',
        prodWidth: 'Adicione uma ou mais larguras',
        prodLength: 'Adicione um ou mais comprimentos',
        prodThickness: 'Adicione uma ou mais espessuras',
        specie: 'Espécies',
        sendSpecie: 'Digite uma ou mais espécies para o produto',
        volumeM3: 'M³',
        pieces: 'Peças',
        noOptions: 'Não há mais opções',

        nextShip: 'Próximos embarques',
        notNextShip: 'Não há próximos embarques previstos.',
        notNextInfo: 'Não há informações disponíveis.',

        po: 'Ordem do pedido',
        options: 'Opções',
        information: 'Informações',
        catalog: 'Catálogo',
        acabaments: 'Acabamentos',
        layersOfProduct: 'Digite uma ou mais camadas',
        photoProduct: 'Upload da foto do produto (tamanho limite 240kbs, formatos aceitos, JPG e PNG)',

        productIdentification: 'Identificação do produto',
        selectProductSupplier: 'Selecione o fornecedor do produto',
        selectProductCategory: 'Selecione a categoria do produto',
        enterProductName: 'Digite o nome do produto',
        enterProductNameInEnglish: 'Digite o nome do produto, em inglês',
        productNameInPortuguese: 'Nome do produto (em português)',
        productNameInEnglish: 'Nome do produto (em inglês)',
        otherLanguageIsRequired: 'Ao preencher a descrição em um idioma, o outro torna-se obrigatório.',
        internalNote: 'Observação interna',
        layerNote: 'Observação sobre camadas',
        addInternalNote: 'Adicione uma observação interna sobre o produto (máx. 160 caracteres)',
        addLayerNote: 'Adicione uma observação sobre as camadas do produto (máx. 160 caracteres)',
        descriptionPtBr: 'Descrição (em português)',
        enterDescriptionPtBr: 'Adicione uma descrição para o produto (máx. 160 caracteres)',
        description: 'Descrição (em inglês)',
        enterDescription: 'Adicione uma descrição para o produto, em inglês (máx. 160 caracteres)',
        labelTypeProduct: 'Categoria',

        of: 'De',
        until: 'Até',
        dimensions: 'Dimensões do produto',
        selectMetric: 'Selecione a medida',

        thickness: 'Espessuras',
        sendThickness: 'Digite uma ou mais espessuras',
        onlyThickness: 'Espessura',
        sendOnlyThickness: 'Digite uma espessura',
        typeMetricThickness: 'Espessura - tipo de medida',

        width: 'Larguras',
        sendWidth: 'Digite uma ou mais larguras',
        onlyWidth: 'Largura',
        sendOnlyWidth: 'Digite uma largura',
        typeMetricWidth: 'Largura - tipo de medida*',

        length: 'Comprimentos',
        sendLength: 'Digite um ou mais comprimentos',
        onlyLength: 'Comprimento',
        sendOnlyLength: 'Digite um comprimento',
        typeMetricLength: 'Comprimento - tipo de medida*',
        genericMessageValues: 'Os valores adicionados não podem se sobrepôr.',
        genericErrorTag: 'Adicione apenas números, com um máximo de 20 tags no campo.',
        genericErrorTagNoLimit: 'Adicione apenas números no campo.',

        notInformated: 'Não informado',
        labelFinishies: 'Acabamentos',
        labelNotDocuments: 'Não existem documentos para esse pedido',
        labelNotFollowUp: 'Não houve a realização de follow-up para esse pedido.',
        labelInspectionNotPerformed: 'Não houve a realização da inspeção para esse pedido.',
        labelPendingInspection: 'Inspeção pendente: Pedido aguardando o envio da inspeção.',
        labelFirstQuestion: '1. Espessura dos produtos',
        labelSecondQuestion: '2. Largura dos Produtos',
        labelThirdQuestion: '3. Comprimento dos Produtos',
        labelFourthQuestion: '4. Face do produto',
        labelFifthQuestion: '5. Fotos dos produtos nos pallets',
        labelSixthQuestion: '6. Fotos das marcas do pallets',
        labelInspectDate: 'DATA DE INSPEÇÃO',
        labelCatalogoDeProdutos: 'Status',
        labelDescriptionImageProfile:
          'Faça o upload da imagem que será a principal do seu perfil. Sugerimos que seja o logotipo da sua empresa, caso possua.',
        labelDescriptionImages:
          'Faça o upload de imagens da sua empresa, para ser vista pelos importadores da WoodFlow. Elas serão adicionadas ao seu perfil.',
        labelUploadPhotoProduct: 'Fazer upload da foto do produto',
        labelNomeEmpresa: 'Nome da empresa para exibição*',
        labelFundadaEm: 'Fundada em*',
        labelHistoriaDaEmpresa: 'História da empresa*',
        labelDescrevaHistoria: 'Descreva brevemente a história da empresa (máx. 1250 caracteres)',
        labelHistoriaEn: 'História da empresa - Versão em inglês (opcional)',
        labelDescrevaHistoriaEn: 'Descreva brevemente a história da empresa, agora, em inglês (máx. 1250 caracteres)',
        labelAtuacaoEmpresa: 'Atuação da empresa*',
        labelDescrevaAtuacao:
          'Descreva brevemente o tipo de produtos ou mercados que a empresa atua (máx. 1250 caracteres)',
        labelAtuacaoEmpresaEn: 'Atuação da empresa - Versão em inglês (opcional)',
        labelDescrevaAtuacaoEn:
          'Descreva brevemente o tipo de produtos ou mercados que a empresa atua, agora, em inglês (máx. 1250 caracteres)',
        labelCertificados: 'Certificados (opcional)',
        labelSelecioneCertificados: 'Selecione os certificados que sua empresa possui',
        labelImagemPerfil: 'Imagem de perfil',
        labelFotosEmpresa: 'Fotos da sua empresa (máx. 4 fotos)',

        labelPerfilDaEmpresa: 'Perfil da empresa',
        labelCriacao: 'Criação do perfil',
        labelPerfil: 'Perfil',
        labelEditarPerfil: 'Editar perfil',
        labelPerfilPublicado: 'O perfil foi publicado!',
        labelMessagePerfilPublicado: 'Parabéns, seu perfil foi publicado na WoodFlow.',
        labelPerfilRevisao: 'O perfil está em revisão',
        labelPreencherPerfil: 'Obrigado por preencher o perfil da sua empresa.',
        labelPublish: 'Neste momento, ele está em revisão pela equipe WoodFlow, e em breve estará publicado.',
        labelCompleteProfile: 'Complete o perfil para ser visto por importadores do mundo todo',
        labelVitrine: 'A WoodFlow é a maior vitrine de fabricantes brasileiros de madeira.',
        labelProfileImporter:
          'Para que o perfil apareça para importadores do mundo todo, complete o formulário abaixo.',
        labelNotApply: 'Não aplicável',
        labelNewPhoto: 'Carregar outra imagem',
        labelsEditPhoto: 'Editar foto',
        labelProductNotFound: 'Nenhum produto encontrado',
        labelCustomerNotFound: 'Nenhum cliente encontrado',
        labelSellerNotFound: 'Nenhum fornecedor encontrado',
        labelDescProductNotFound: 'Nenhum produto atende seus critérios de busca. Tente buscar com outros filtros.',
        labelDescCustomerNotFound: 'Nenhum cliente atende seus critérios de busca.',
        labelDescSellerNotFound: 'Nenhum fornecedor atende seus critérios de busca. Tente buscar com outros filtros.',
        labelAllProducts: 'Ver todos os produtos',
        labelRequestQuote: 'Solicitar orçamento',
        labelSearchName: 'Nome',
        labelPageNotFound: 'Oops, página não encontrada',
        labelPageNotExists: 'Esta página não existe ou não pode ser encontrada',
        labelQuoteSucess: 'Cotação criada com sucesso!',
        labelTeamWill: 'Nossa equipe estará em busca das melhores ofertas e em breve entrará em contato com você!',
        labelShowMyQuotes: 'Mostre-me minhas cotações',
        labelSelectProduct: 'Selecione a categoria de produto',
        labelEspecify: 'Especifique qual',
        labelSelectUnitM: 'Selecione a unidade de medida',
        labelWidth: 'Largura (mm)*',
        labelEspessura: 'Espessura (mm)',
        labelLength: 'Comprimento (mm)*',
        labelEspecifyProd: 'Especifique o volume',
        labelComentsQuote: 'Você pode adicionar mais informações sobre sua cotação aqui.',
        labelSendNewQuote: 'Enviar cotação',
        labelReturn: 'Voltar',
        labelNewQuote: 'Nova cotação',
        labelTextNewQuote:
          'Você que está cotando para a {{company}}, caso queira cotar para outra empresa, por favor selecione no menu acima a empresa que deseja.',
        labelSpecify: 'Especifique qual',
        labelUnitCrud: 'Unidade de medida',
        labelDimensionsCrud: 'Dimensões',
        labelComents: 'Comentários',
        labelQuoteWork: 'Como funciona a cotação:',
        labelSendQuote: 'Mande sua cota',
        labelTeamReiceve: 'Nossa equipe recebe sua cotação',
        labelBestOffers: 'Encontramos as melhores ofertas',
        labelCallYou: 'Nós te chamamos',
        labelTextQuoteInformative:
          'Tudo é feito o mais rápido que podemos! Não há mais espera. E a melhor parte, é grátis!',
        labelStatusFilter: 'Filtro de status',
        labelSelectCountry: 'Selecione o pais',
        labelRedirectToLogin: 'Fazer login >',
        labelOr: 'Ou',
        labelsNotGetEmail: 'Não recebeu o e-mail?',
        labelEmailSendFor: 'Um e-mail foi enviado para:',
        labelAccessEmail: 'Acesse seu e-mail',
        labelAreYouProducer: 'Já possui uma conta?',
        labelEnterHere: 'Entre aqui.',
        labelFirstName: 'Nome*',
        labelLastName: 'Sobrenome*',
        labelCompanyName: 'Nome da empresa*',
        labelContryName: 'País*',
        labelAcceptTerms: 'Ao criar sua conta você está concordando com as nossas',
        labelPrivacy: 'Políticas de Privacidade',
        labelMeetWoodflow: 'Conheça a WoodFlow: ',
        labelSelectedProducers: 'Produtores pré-selecionados',
        labelMultipleQuotes: 'Múltiplas cotações',
        labelTransparentNegotiation: 'Negociação transparente',
        labelOnlineTracking: 'Rastreamento online',
        labelImportTheLumber: 'Como membro, você irá: ',
        labelNeed: 'precisa',
        labelBrazilianLumber: 'melhor plataforma de madeira brasileira',
        labelContactWhatsApp: 'Entre em contato com a WoodFlow pelo WhatsApp',
        labelsReSendEmail: 'Reenvie o e-mail',
        labelNotAccount: 'Não tem uma conta?',
        labelDescription: 'Descrição',
        labelResponsible: 'Responsavel',
        labelStatus: 'Status',
        labelDate: 'Data',
        labelFinishing: 'Finalização',
        labelExporter: 'Exportador',
        labelContact: 'Contato',
        labelImporter: 'Importador',
        labelRequest: 'Pedido',
        labelInvoice: 'Invoice',
        labelComments: 'Observações',
        labelIncoterm: 'Incoterm',
        labelBooking: 'Booking',
        labelOrigin: 'Local de origem',
        labelFinalDestination: 'Destino final',
        labelPortOfShipment: 'Porto de embarque',
        labelPortDestination: 'Porto de destino',
        labelPortOfLoading: 'Origem',
        labelPortOfDischarge: 'Destino',
        labelContainerType: 'Tipo container',
        labelNumOfContainer: 'Qtd container',
        labelM3: 'M3',
        labelTransport: 'Transportador',
        labelNameShip: 'Nome do navio',
        labelForgotPassword: 'Esqueceu a senha?',
        labelAlterPassword: 'Altere a senha',
        labelCopyright: 'WoodFlow. Todos os direitos reservados',
        labelBusinessDashboard: 'Acompanhe os seus negócios fechados e monitore o status das entregas',
        labelFilter: 'Filtros',
        labelFilterClear: 'Limpar Filtros',
        labelSearchProducts: 'Pesquise por produtos',
        labelDimensions: 'Dimensões',
        labelQuantityOfPallets: 'Quantidade de pallet',
        labelPiecesOfPallets: 'Peças por pallet',
        labelVolumePerPallet: 'Volume (m³) por pallet',
        labelQuantityOfPieces: 'Quantidade de peças',
        labelTotalVolume: 'Total volume (m³)',
        labelUnityPrice: 'Preço unitário (USD$)',
        labelTotalAmount: 'Preço total (USD$)',
        labelExpectedDate: 'Prevista',
        labelCurrentDate: 'Atual',
        labelRealizedDate: 'Realizada',
        labelCargoReady: 'Prontidão da carga',
        labelArivalAtOrigin: 'Chegada no porto de origem',
        labelLoading: 'Embarque',
        labelArrivalAtDestination: 'Chegada no porto de destino',
        labelLoadingAtDestination: 'Saída do porto de destino',
        labelDelivery: 'Entrega',
        labelPayment: 'Pagamento',
        labelPaymentMethod: 'Forma de pagamento',
        labelStatusPayment: 'Status pagamento',
        labelTotalAmountPayment: 'Valor total',
        labelAdvenced: 'Valor antecipado',
        labelBalance: 'Valor a receber',
        labelBoughtBy: 'Comprado por',
        labelSoldBy: 'Vendido por',
        labelShipment: 'Embarque',
        labelEstimated: 'Estimada',
        labelToBeInformed: 'a ser informado',
        labelEdit: 'Editar',
        labelDetail: 'Detalhe',
        labelUndefined: 'a ser informado',
        labelThereAreNoDocuments: 'Não possui documentos...',
        labelQuality: 'Qualidade',
        labelGlue: 'Cola',
        labelCertificates: 'Certificado',
        labelItems: 'Peças',
        labelUnid: 'Unid',
        labelUnit: '$ Unit',
        labelM3Total: 'M³ total',
        labelHide: 'Ocultar',
        labelNoContent: 'Não existem registros...',
        labelShippingAgent: 'Agente',
        labelVessel: 'Navio',
        labelToBeConfirmed: 'a confirmar',

        labelProductCat: 'Produto visível no Catálogo de produtos',
        labelStatusProfile: 'Status do perfil',
        labelActive: 'Ativo',
        labelInactive: 'Inativo',
        labelPhotoProduct: 'Foto do produto (Tamanho limite 240kbs, formatos aceitos, JPG e PNG)',
        labelRemoveImage: 'Remover imagem',
        labelAddImg: 'Adicionar imagem',
        labelDetailsQuote: 'Detalhes do pedido de cotação',
        labelRequester: 'Solicitante',
        labelProduct: 'Produto',
        labelMetrics: 'Medidas',
        labelDetails: 'Comentários',
        labelJustify: 'Justificativa',
        labelDetailStatus: 'Detalhes do status',
        labelEditQuote: 'Editar cotação',
        labelsM3OrPieces: 'M³ ou peças',
        labelsSendEmails: 'Selecione os e-mails que deseja notificar',
        labelsCreateSuccess: 'Criado com sucesso',
        labelsCompanies: 'Procurar por fornecedor',
        labelsDocuments: 'Escolher documento',
        labelsAdress: 'Endereço',
        labelsSearchAdress: 'Digite para pesquisar',
        labelsRoles: 'Atuação',
        labelsCustomer: 'Empresa',
        labelsPhoneNumber: 'Telefone (opcional)',
        labelsComercialContact: 'Contato comercial',
        labelsComercialEmail: 'E-mail comercial',
        labelsProductionContact: 'Contato produção',
        labelsProductionEmail: 'E-mail produção',
        labelsLogisticContact: 'Contato logística',
        labelsLogisticEmail: 'E-mail logística',
        labelsDespachContact: 'Contato despachante',
        labelsDespachEmail: 'E-mail despachante',
        labelDocumentType: 'Tipo de documento',
        labelArrivalForecast: 'Nova data de previsão de chegada (ETA)',
        labelUpdateDate: 'Atualização',
        labelShipmentForecast: 'Nova data de previsão de embarque (ETD)',
        labelNoRecords: 'Não existem registros...',
        labelsCompaniesCrud: 'Empresa',
        labelsNotifyCystomer: 'Notificar cliente sobre nova atualização',
        labelFinish: 'Finalizado',
        labelSpecieProduct: 'Espécie',
        labelPurposeProduct: 'Finalidade',
        labelQualityProduct: 'Qualidade',
        labelGlueProduct: 'Cola',
        labelCertificateProduct: 'Certificado(s)',
        labelLengthProduct: 'Comprimento',
        labelWidthProduct: 'Largura',
        labelThicknessProduct: 'Espessura',
        labelLayersProduct: 'Camadas',
        labelDescriptionPTBRProduct: 'Nome do produto em português',
        labelDescriptionProduct: 'Nome do produto em inglês (opcional)',
        labelCompaniesProduct: 'Vincular produto a empresas',
        labelPublicProfile: 'Perfil público',
        labelFounded: 'Ano de fundação',
        labelHistoryPT: 'História da empresa - PT',
        labelActingPT: 'Atuação da empresa - PT',
        labelActingEN: 'Atuação da empresa - EN',
        labelHistoryEN: 'História da empresa - EN',
        labelLinkMovie: 'Link para vídeo da página (opcional)',
        labelLinkTour: 'Link para tour da empresa (opcional)',
        labelImageProfile: 'Imagem de perfil',
        labelImageUpload: 'Upload de imagens',
        labelDescriptionEditStatus1: 'Em edição',
        labelDescriptionEditStatus2: 'Aguardando publicação',
        labelDescriptionEditStatus3: 'Publicado',
        labelFilterStatus: 'Filtro por status do perfil',

        labelDescriptionEditEdit: 'Seu perfil está em edição',
        labelDescriptionEditChange:
          'Altere suas informações já enviadas ou publicadas na WoodFlow. Você pode fazer alterações sempre que',
        labelDescriptionEditPublish:
          'necessário. Para enviar as alterações a serem publicadas, clique em “Enviar para publicação”',

        labelStatusDate: 'Nova data para status',

        sendPortName: 'Digite o nome do porto',
        portName: 'Nome do porto',
        cdCountry: 'Código do país',
        sendCdCountry: 'Digite o código do país',
        country: 'País',
        sendCountry: 'Digite o nome do país',
        labelPortNotFound: 'Nenhum porto encontrado.',
        labelDescPortNotFound: 'Nenhum port atende seus critérios de busca.',
      },

      radio: {
        titlePayment: 'Pagamento antecipado',
        yes: 'Sim',
        no: 'Não',
      },

      placeholders: {
        holderPaymentAdditionalInfo: 'Adicione aqui outras informações relevantes sobre as informações bancárias',
        holderAcabaments: 'Selecione um ou mais acabamentos para o produto',
        holderFilterProducts: 'Procure por nome de produto, tipo ou espécie',
        holderResponsibleOrder: 'Responsável pela compra',
        holderResponsible: 'Responsavel',
        holderStatus: 'Status',
        holderExporter: 'Exportador',
        holderContact: 'Contato',
        holderImporter: 'Importador',
        holderRequest: 'Pedido',
        holderInvoice: 'Invoice',
        holderComments: 'Observação',
        holderPassword: 'Senha',
        holderNewPassword: 'Nova senha',
        holderConfirmPassword: 'Repita nova senha',
        holderConfirmReicevedPassword: 'Senha recebida por e-mail',
        holderEmail: 'E-mail',
        holderBooking: 'Booking',
        holderOrigin: 'Local de origem',
        holderFinalDestination: 'Destino final',
        holderPortOfShipment: 'Porto de embarque',
        holderContainerType: 'Tipo container',
        holderPortDestination: 'Porto de destino',
        holderPort: 'Procure pelo porto',
        holderNumOfContainer: 'Qtd container',
        holderM3: 'M3',
        holderTransport: 'Transportador',
        holderNameShip: 'Nome do navio',
        holderQueryFilter: 'Buscar...',
        holderFilterUsers: 'Procure por nome, sobrenome ou e-mail de usuário',
        holderCompanies: 'Empresas',
        holderTypeProduct: 'Procure por um tipo de produto',
        holderSpecieProduct: 'Procure por uma espécie de produto',
        holderPurposeProduct: 'Procure por uma finalidade do produto',
        holderQualityProduct: 'Procure por uma qualidade do produto',
        holderQuality: 'Selecione uma ou mais qualidades para o produto',
        holderSpecie: 'Selecione uma ou mais espécies para o produto',
        holderGlueProduct: 'Procure por uma cola do produto',
        holderCertificate: 'Selecione um ou mais certificados',
        holderDate: 'Selecione a data',
        holderNewUser: 'Nome',
        holderSearchDesktop: 'Buscar no meu computador',
        holderFormatsAccepted: 'Formatos aceitos: .xlsx, jpeg, PDF, png',
        holderProfile: 'Perfil',
        holderCodCertificate: 'Digite o código do certificado',
        holderFilterClients: 'Procure pelo nome do cliente',
      },
      buttons: {
        editInformations: 'Editar informações',
        newClient: 'Novo cliente',
        sendInvite: 'Enviar convite',
        shareAccess: 'Compartilhar acesso',
        startNow: 'Começar agora! >',
        remove: 'Remover',
        newProduct: 'Cadastrar produto',
        onlySave: 'Salvar',
        sendToSubmit: 'Enviar para publicação',
        saveDraft: 'Salvar como rascunho',

        newPort: 'Novo porto',

        save: 'Cadastrar',
        login: 'Fazer login',
        send: 'Enviar',
        forgot: 'Enviar',
        search: 'Buscar',
        add: 'Adicionar',
        columns: 'Colunas',
        confirmPassword: 'Confirma senha',
        goToHome: 'Ir para home',
        saveAndSend: 'Salvar e enviar',
        saveCustomer: 'Cadastrar empresa',
        editCustomer: 'Editar empresa',
        edit: 'Editar',
        newRegister: 'Novo cadastro',
        delete: 'Excluir',
        cancel: 'Cancelar',
        continue: 'Continuar',
        addProduct: 'Adicionar produto',
        addDocument: 'Adicionar documento',
        saveAndNotify: 'Salvar e notificar por e-mail',
        newUpdate: 'Nova atualização',
        newFollow: 'Novo Follow Up',
        att: 'Atualizar',
        saveAndAddDocuments: 'Salvar e adicionar documentos',
        saveAndGoToStep: 'Salvar e ir para Etapa',
        editDetails: 'Editar informações',
        downloadSelecteds: 'Download',
      },
      sideBar: {
        home: 'Lista de negócios',
        register: 'Cadastrar um negócio',
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        page: 'página',
        of: 'de',
        ordersPerPage: 'Pedidos por página',
        productsPerPage: 'Produtos por página:',
        quotesPerPage: 'Cotações por página',
        clientsPerPage: 'Clientes por página',
        documentsPerPage: 'Documentos por página',
        followUpsPerPage: 'Follow ups por página',
      },
      table: {
        piecePallet: 'Peças p/ pallet',
        country: 'País',
        contact: 'Nome do contato',
        phone: 'Telefone',
        typeDocument: 'Tipo de documento',
        nameFile: 'Nome do arquivo',
        valid: 'Validade',
        produtos: 'Produtos',

        docType: 'tipo de documento',
        expDate: 'data de validade',
        downloadFile: 'download',

        provider: 'Fornecedor',

        costFreight: 'Valor do frete',
        unitPrice: 'Preço unitário',

        dateOfCreate: 'Data de criação',
        image: 'Imagem',
        typeProduct: 'Tipo de produto',
        measure: 'Unidade de medida',
        quoteAccepted: 'Propostas aceitas',
        file: 'Arquivo',
        mesures: 'medidas',
        unit: 'unidade',
        lastUpdated: 'Última atualização',
        product: 'Produto',
        qty: 'Qty',
        unity: 'unid',
        price: 'Preço',
        notes: 'Observações',
        date: 'Data',
        updatedDate: 'Data de atualização',
        documentName: 'Nome do documento',
        documentType: 'Tipo de documento',
        paymentMethod: 'Forma de pagamento',
        paymentStatus: 'Status do pagamento',
        advanced: 'Valor antecipado',
        totalAmount: 'Valor total',
        balance: 'Valor a receber',
        productName: 'Nome do produto',
        supplier: 'Fornecedor',
        customer: 'Cliente',
        orderDate: 'Data do Pedido',
        contract: 'Contrato',
        width: 'Largura mm',
        length: 'Comprimento mm',
        thickness: 'Espessura mm',
        layers: 'Camadas mm',
        grade: 'Qualidade',
        certificates: 'Certificados',
        pallets: 'Pallet',
        qtdPecaPallet: 'Peças',
        panels_per_crate: 'Painéis por caixa',
        productPrice: 'Preço do produto',
        carrier: 'Operador',
        vessel: 'Embarcação',
        ordersPerPage: 'Pedidos por página',
        viewNote: 'Ver nota',
        glue: 'Cola',

        nameProduct: 'Nome do produto',
        fileName: 'Nome do arquivo',
        type: 'Tipo',
        responsible: 'Responsável',
        document: 'Documento',
        more: 'Ver mais',
        att: 'Data de atualização',
        currentStatus: 'Status atual',
        shipmentForecast: 'Previsão embarque',
        arrivalForecast: 'Previsão chegada',
        acting: 'Atuação',
        company: 'Empresa',
        nameClient: 'Nome do cliente',
        customerClient: 'País do cliente',
        contactComercial: 'Contato comercial',
        situation: 'Situação',
        port: 'Porto',
        codeCountry: 'Código do país',
      },
      modal: {
        removeFollow: 'Você está prestes a deletar a atualização. Ao excluir, a ação não poderá ser desfeita.',
        modalTitleProducts: 'Produtos',
        modalTitleUploadDocument: 'Novo documento',
        modalTitleActions: 'Ações',
        newProduct: 'Cadastro de produto',
        editCompany: 'Editar empresa',
        editProduct: 'Editar produto',
        createCustomer: 'Criar empresa',
        confirmDelete: 'Você está prestes a deletar um negócio, tem certeza desta ação?',
        confirmDeleteCompany: 'Você está prestes a deletar uma empresa, tem certeza desta ação?',
        confirmDeleteProduct: 'Você está prestes a deletar um produto, tem certeza desta ação?',
        confirmDeleteDocument: 'Você está prestes a deletar um documento, tem certeza desta ação?',
        confirmDeletePhoto: 'Você está prestes a deletar uma foto, tem certeza desta ação?',

        messsageFirstAcess:
          'Defina suas preferências e obtenha as melhores ofertas da WoodFlow. Em quais produtos você está interessado?',
        welcomeToWoodflow: 'boas vindas a woodflow!',
        btnNotInteressed: 'Não possuo interesse',
        btnSavePreferences: 'Salvar preferências',
        textPreferencesOffers: 'Defina suas preferências e receba as melhores ofertas da WoodFlow!',
        addPreferences: 'Adicionar preferências',

        removeProductTitle: 'Excluir produto',
        removeProductSubTitle: 'Tem certeza que deseja excluir esse produto?',
        removeProductDescription:
          'Após a exclusão, você perderá todas as informações cadastradas e deverá adicioná-las novamente.',

        removeMultiProductTitle: 'Excluir produto(s)',
        removeMultiProductSubTitle: 'Tem certeza que deseja excluir esse(s) produto(s?',
        removeMultiProductDescription:
          'Após a exclusão, você perderá todas as informações cadastradas e deverá adicioná-las novamente.',
      },
      uploadFiles: {
        uploadFile: 'Faça o upload de um arquivo',
        awbDocuments: 'Awb Documentos Originais',
        shipmentExperience: 'Conhecimento Embarque',
        certificateOrigin: 'Certificado de origem',
        containerRom: 'Romaneio por container',
        contract: 'Contrato',
        cte: 'cte',
        draftBI: 'Draft BI',
        expressRealease: 'Express Release',
        certifcateFum: 'Certificado de fumigação',
        certifcateHit: 'Certificado hit',
        certifcateInspecion: 'Certificado de inspeção',
        certifcateSecurity: 'Certificado do seguro',
        invoice: 'Fatura',
        creditCard: 'Cartão de credito',
        fiscalNote: 'Nota fiscal',
        romanian: 'Romaneio',
        photos: 'Fotos',
        phytosanitary: 'Fitossanitario',
        proformaInvoice: 'Fatura proforma',
        get: 'Pedido',
        signedProformaInvoice: 'Fatura proforma assinada',
        warehouseLetter: 'Carta do armazem',
        downloadSuccess: 'Download feito com sucesso',
        doctoSelected: 'Documentos selecionados',
      },
      response: {
        saveSuccess: 'Informações salvas com sucesso.',
        inviteSuccess: 'Convite(s) enviado(s) com sucesso',
        emailError: 'Esse e-mail não está cadastrado, por favor, verifique e tente novamente!',

        editDraft: 'Rascunho salvo com sucesso',
        editAnalise: 'Enviado para análise',
        editSuccess: 'Editado com sucesso',
        postSuccess: 'Cadastrado com sucesso',
        deleteSuccess: 'Deletado com sucesso',
        linkCopy: 'Link copiado',
        emailSendSucess: 'Foi enviado um e-mail, acesse o e-mail para ter o direito de cadastrar uma nova senha!',
        emailNotExists: 'E-mail não existe',
        successduplicated: 'Duplicado com sucesso',
      },
      emptyState: {
        shipmentSchedule: 'Mas não se preocupe, assim que fizer seu primeiro pedido ele aparecerá aqui!',
        notFoundOrders: 'Ainda não há pedidos.',
        notFound: 'Nenhum resultado encontrado.',
        noProductsToEdit: 'Não há produtos adicionados.',
        noProductsToSaveBusiness:
          'Não há produtos adicionados. Para salvar o negócio, é obrigatório adicionar pelo menos um produto.',
      },
      newSale: {
        shippingTitle: 'Informação de embarque',
        shipName: 'Nome do navio',
        estimatedArrival: 'Previsão de chegada',
        buttonAllDetails: 'Ver mais detalhes',
        buttonGenerateProforma: 'Gerar Proforma',
        copyBooking: 'Booking copiado para a área de transferência.',
        copyInvoice: 'Invoice copiado para a área de transferência.',
        product: {
          name: 'Nome',
          length: 'Comprimento MM',
          width: 'Largura MM',
          thickness: 'Espessura MM',
          total: 'Total m²',
          price: 'Preço Unitário',
          seeMore: 'ver mais',
        },
        container: {
          containerType: 'Tipo do container',
          qtyOfContainers: 'Qtd de containers',
          totalShipped: 'Total m³',
        },
      },
    },
  },
};
