import React from 'react';

interface IContext {
  setPage: (page: number) => void;
  page: number;
  pages: number;
  setPages: (pages: number) => void;
  name: string;
  setName: (name: string) => void;
  certificates: any;
  setCertificates: (certificates: any) => void;
  certificatesKeys: any;
  setCertificatesKeys: (certificatesKeys: any) => void;
}

interface IProvider {
  children: React.ReactNode | React.ReactNode[];
}

const filterSuppliersContext = React.createContext<IContext>({} as IContext);

export function useFilterSuppliers(): IContext {
  return React.useContext(filterSuppliersContext);
}

export function SuppliersProvider({ children }: IProvider): React.ReactElement {
  const [page, setPage] = React.useState<number>(1);
  const [pages, setPages] = React.useState<number>(0);
  const [name, setName] = React.useState<string>('');
  const [certificates, setCertificates] = React.useState<any>();
  const [certificatesKeys, setCertificatesKeys] = React.useState<any>([]);

  const value = {
    page,
    setPage,
    pages,
    setPages,
    name,
    setName,
    certificates,
    setCertificates,
    certificatesKeys,
    setCertificatesKeys,
  };

  return <filterSuppliersContext.Provider value={value}>{children}</filterSuppliersContext.Provider>;
}
