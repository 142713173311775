/* eslint-disable @typescript-eslint/no-unused-vars */
export const key = process.env.REACT_APP_RECAPTCHA_TOKEN;
export default function grecaptcha(action: any): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      window.grecaptcha.ready((_: any) => {
        try {
          window.grecaptcha.execute(key, { action }).then(
            (token: string | PromiseLike<string>) => {
              resolve(token);
            },
            (error: any) => {
              reject(error);
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}
