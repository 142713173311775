import HttpClient from './httpClient';
import { IAuth } from '../interfaces/IAuth';
import grecaptcha from './recaptcha.service';

interface IMessageResponse {
  message: string;
}

class AuthsService {
  static async sign(username: string, password: string): Promise<IAuth> {
    const tokeRecaptcha = await grecaptcha('login');

    const { data } = await HttpClient.api.post<IAuth>('/oauth/token', {
      username,
      password,
      grant_type: 'password',
      recaptcha_token: tokeRecaptcha,
    });

    return data;
  }

  static async sendPasswordResetEmail(email: string): Promise<IMessageResponse> {
    const tokeRecaptcha = await grecaptcha('login');

    const { data } = await HttpClient.api.post<IMessageResponse>('/users/esqueciMinhaSenha', {
      email,
      recaptcha_token: tokeRecaptcha,
      isAdm: false,
    });

    return data;
  }

  static async resetPassword(password: string, confirmPassword: string, token: string): Promise<IMessageResponse> {
    const tokeRecaptcha = await grecaptcha('login');

    const { data } = await HttpClient.api.put<IMessageResponse>(`users/gerarNovaSenha/${token}`, {
      password,
      c_password: confirmPassword,
      recaptcha_token: tokeRecaptcha,
    });

    return data;
  }

  static async resetPasswordFirstAccess(
    atualPassword: string,
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<IMessageResponse> {
    const tokeRecaptcha = await grecaptcha('login');

    const { data } = await HttpClient.api.post<IMessageResponse>(`users/alterarMinhaSenha/${token}`, {
      senha_atual: atualPassword,
      password,
      c_password: confirmPassword,
      recaptcha_token: tokeRecaptcha,
    });

    return data;
  }
}

export default AuthsService;
