import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { messages } from './languages';

const i18nConfig = {
  fallbackLng: 'pt',
  defaultNS: 'translations',
  ns: ['translations'],
  resources: messages,
  detection: {
    order: ['localStorage'],
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
