import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Image } from 'react-bootstrap';
import StepOne from '../../statics/step1.png';
import StepTwo from '../../statics/step2.png';
import Success from '../../statics/modalSucess.png';
import Modal from '../Modal';
import Button from '../Button';
import './styles.scss';
import UsersService from '../../services/users.service';
import { useAuth } from '../../contexts/AuthContext';
import { useLoader } from '../../contexts/LoaderContext';
import { StatusProfile } from '../../enums/statusProfile';

const ModalPreferences = ({
  isOpen,
  setIsOpen,
  options,
  defaultKeys,
  setSelected,
  firstAccess,
  handleChange,
  cancel,
  user,
  show,
  setShow,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const { updateUser } = useAuth();
  const history = useHistory();
  const { setShowLoader } = useLoader();

  const [newDefaultKey, setNewDefaultKey] = React.useState<any>([]);
  const [nextStep, setNextStep] = React.useState<boolean>(false);
  const [terms_of_use, setTermsOfUse] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (user?.status_profile === StatusProfile.STATUS_APPROVED && !!firstAccess) {
      setNextStep(true);
    } else {
      setNextStep(false);
    }
  }, [firstAccess, user]);

  const onChangeCheckBox = (value: any, event: any): void => {
    if (event.target.checked) {
      setSelected([...defaultKeys, value]);
    } else {
      const newColumnsKeys = defaultKeys?.filter((opt: string) => opt !== value).map((opt: string) => opt);

      setSelected(newColumnsKeys);
    }
  };

  const handleChangeFirstLogin = React.useCallback(async (): Promise<void> => {
    try {
      setShowLoader(true);
      if (user) {
        await UsersService.setFirstLogin();
        setTimeout(() => {
          setNextStep(false);
          setShowLoader(false);
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      setShowLoader(false);
    }
  }, [setShowLoader, user]);

  const handleChangePrivacyPolicies = React.useCallback(async (): Promise<void> => {
    try {
      setShowLoader(true);
      if (user) {
        await UsersService.setPrivacyPolicies();
        const newUser = await UsersService.checkToken();
        await updateUser(newUser);

        setShowLoader(false);
        setShow(false);
        history.go(0);
      }
    } catch (err) {
      console.error(err);
      setShowLoader(false);
    }
  }, [setShowLoader, user, updateUser, setShow, history]);

  React.useEffect(() => {
    setNewDefaultKey(defaultKeys);
  }, [defaultKeys]);

  React.useEffect(() => {
    async function loadUser(): Promise<void> {
      const newUser = await UsersService.checkToken();

      await updateUser(newUser);
    }

    if (!nextStep) {
      loadUser();
    }
  }, [nextStep, updateUser]);

  const renderBodyModal = (): React.ReactElement => {
    const maxCheckboxesPerColumn = 3;
    const numColumns = Math.ceil(options?.length / maxCheckboxesPerColumn);

    const columns = [];

    for (let i = 0; i < numColumns; i++) {
      const startIndex = i * maxCheckboxesPerColumn;
      const endIndex = startIndex + maxCheckboxesPerColumn;
      const columnOptions = options?.slice(startIndex, endIndex);

      const column = (
        <Col className="alignChecks" key={i}>
          {columnOptions?.map((opt: any) => (
            <div key={opt?.value}>
              <label className="text-checked" htmlFor={opt?.value}>
                <>
                  <input
                    className="checkFilters"
                    type="checkbox"
                    id={opt?.value}
                    value={opt?.value}
                    defaultChecked={newDefaultKey?.find((optChecked: string) => optChecked === opt?.value)}
                    checked={newDefaultKey?.find((optChecked: string) => optChecked === opt?.value)}
                    onChange={(e) => {
                      onChangeCheckBox(opt?.value, e);
                    }}
                  />
                  {opt.label}
                </>
              </label>
            </div>
          ))}
        </Col>
      );

      columns.push(column);
    }

    return <>{columns}</>;
  };

  const renderFormModalPreferences = (): React.ReactElement => (
    <Container className="containerModal mb-4">
      <Col className="textsModal">
        {firstAccess && <h6>{t('modal.welcomeToWoodflow')}</h6>}
        <h3>{t('modal.messsageFirstAcess')}</h3>
      </Col>

      {renderBodyModal()}
      <Col md={12} className="colButtons">
        <Button cy="btn-cancel" type="button" variant="transparent-green" onClick={() => cancel()}>
          {t('modal.btnNotInteressed')}
        </Button>
        <Button cy="btn-save" type="button" disabled={!defaultKeys?.length} onClick={handleChange}>
          {t('modal.btnSavePreferences')}
        </Button>
      </Col>
    </Container>
  );

  const renderFormModalAccepted = (): React.ReactElement => (
    <Container className="containerModal mb-4">
      <Col className="d-flex align-items center justify-content-center textsModalApprove">
        <h3>{t('titles.verifyAccount')}</h3>
      </Col>
      <Col className="d-flex align-items center justify-content-center textsModalApprove">
        <p>{t('titles.messageVerify')}</p>
      </Col>

      <Col className="d-flex align-items center justify-content-center mt-4">
        <Image src={Success} />
      </Col>
      {user?.perfil_id === 9 && (
        <Col className="d-flex align-items center justify-content-center mt-4 gap-2 alignChecks">
          <label className="text-checked" htmlFor="checked">
            <input
              type="checkbox"
              className="checkFilters"
              onChange={() => {
                setTermsOfUse(!terms_of_use);
              }}
              checked={terms_of_use}
            />
            <span>{t('labels.accepted')}</span>
            <a href="https://www.woodflow.com.br/privacy-policy/" target="_blank" rel="noopener noreferrer">
              {t('footer.labelPrivacyPolicy')}
            </a>
          </label>
        </Col>
      )}

      <Col md={12} className="d-flex align-items center justify-content-center mt-4 textsModalApprove">
        <Button
          cy="btn-save"
          type="button"
          className="buttonStart"
          onClick={user?.perfil_id === 9 ? () => handleChangePrivacyPolicies() : () => handleChangeFirstLogin()}
          disabled={user?.perfil_id === 9 && !terms_of_use}
        >
          {t('buttons.startNow')}
        </Button>
      </Col>
    </Container>
  );

  return (
    <>
      <Modal
        show={user?.perfil_id === 9 ? show : nextStep}
        handleClose={() => setNextStep(false)}
        size="lg"
        isCrud
        image={
          <Col className="d-flex align-items-center justify-content-center mt-2 mb-4">
            {user?.perfil_id !== 9 && <Image src={StepOne} />}
          </Col>
        }
      >
        {renderFormModalAccepted()}
      </Modal>
      <Modal
        show={isOpen && !nextStep}
        handleClose={() => setIsOpen(false)}
        size="lg"
        isCrud
        image={
          <Col className="d-flex align-items-center justify-content-center mt-2 mb-4">
            <Image src={StepTwo} />
          </Col>
        }
      >
        {renderFormModalPreferences()}
      </Modal>
    </>
  );
};

export default ModalPreferences;
