import React, { lazy } from 'react';

const Supply = lazy(() => import('../pages/Suppliers/Business/Register/CardSupply/Supply'));
const ShipmentSchedule = lazy(() => import('../pages/ShipmentSchedule'));
const ConfirmAccount = lazy(() => import('../pages/Authentication/ConfirmAccount'));
const SuppliersProfile = lazy(() => import('../pages/Suppliers/Profile'));
const Customers = lazy(() => import('../pages/Suppliers/Customers'));
const NewClient = lazy(() => import('../pages/Suppliers/Customers/NewCustomer'));
const Products = lazy(() => import('../pages/Profile/Products'));
const Profile = lazy(() => import('../pages/Profile'));
// const Dashboard = lazy(() => import('../pages/Suppliers/Dashboard'));
const DetailsEdit = lazy(() => import('../pages/Suppliers/Details'));
const RegisterBusiness = lazy(() => import('../pages/Suppliers/Business/Register'));
const Metrics = lazy(() => import('../pages/Suppliers/Dashboard/Metrics'));
const AddProfile = lazy(() => import('../pages/Profile/Products/Actions'));
const DetailsShipmentSchedule = lazy(() => import('../pages/ShipmentSchedule/Details'));
const DetailsUnauthenticated = lazy(() => import('../pages/ShipmentSchedule/DetailsUnauthenticated'));
const Login = lazy(() => import('../pages/Authentication/Login'));
const ForgotPassword = lazy(() => import('../pages/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Authentication/ResetPassword'));
const Ports = lazy(() => import('../pages/Ports'));
const Dashboard = lazy(() => import('../pages/NewDashboard'));
const NewPort = lazy(() => import('../pages/Ports/NewPort'));

const Error = lazy(() => import('../pages/Error'));

interface IProp {
  path: string;
  component: React.FunctionComponent;
  public?: boolean;
}

export const providerRoutes: IProp[] = [
  {
    path: `/business-shared/*`,
    component: DetailsUnauthenticated,
    public: true,
  },
  {
    path: '/new-business/supply/:id/step/:step',
    component: Supply,
  },
  {
    path: '/new-business/:id?',
    component: RegisterBusiness,
  },
  {
    path: '/edit-business/:id?',
    component: DetailsEdit,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/new-client/:id?',
    component: NewClient,
  },
  {
    path: '/clients',
    component: Customers,
  },
  {
    path: '/ports',
    component: Ports,
  },
  {
    path: '/new-port/:id?',
    component: NewPort,
  },
  {
    path: '/metrics',
    component: Metrics,
  },
  {
    path: '/add-product',
    component: AddProfile,
  },
  {
    path: '/suppliers-profile/:id',
    component: SuppliersProfile,
  },
  {
    path: '/profile',
    component: Profile,
  },
  {
    path: '/complete-profile',
    component: Profile,
  },

  {
    path: '/my-products',
    component: Products,
  },
  {
    path: '/confirm-account',
    component: ConfirmAccount,
    public: true,
  },
  {
    path: `/business/:id`,
    component: DetailsShipmentSchedule,
  },
  {
    path: '/business/shared/:hash',
    component: ShipmentSchedule,
  },
  {
    path: '/business/shared/:hash/documents',
    component: ShipmentSchedule,
  },
  {
    path: '/sales',
    component: ShipmentSchedule,
  },
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    public: true,
  },
  {
    path: '/resetPassword/:token',
    component: ResetPassword,
    public: true,
  },
  {
    path: '/registration/:token',
    component: ResetPassword,
    public: true,
  },
  {
    path: '/login/:hash?',
    component: Login,
    public: true,
  },
  {
    path: '/login/new-quote',
    component: Login,
    public: true,
  },
  {
    path: '*',
    component: Error,
    public: true,
  },
];
