import React from 'react';
import WoodIcon from '../../statics/wood.png';
import './styles.scss';

const Loader = (): React.ReactElement => (
  <div className="loader">
    <img src={WoodIcon} alt="Wood Loading" data-testid="spinner" />
  </div>
);

export default Loader;
