import { ICompanyServiceResponse } from '../interfaces';
import { Links, Meta } from '../interfaces/IMeta';
import HttpClient from './httpClient';

interface ICompaniesService {
  latest_updated: string;
}

export interface ICoordinates {
  coordinates: {
    latitude: string;
    longitude: string;
  };
}
export interface IRules {
  customer: boolean;
  seller: boolean;
  trader: boolean;
}

export interface IPropsSaveCompany {
  name: string;
  notes?: string;
  phone?: string;
  rules: IRules[];
  uuid?: string;
  address?: string;
  city?: string;
  country?: string;
  zip_code?: string;
  coordinates?: ICoordinates;
  typeAccessKeys?: string[];
  contact_fullname?: string;
  tax_identifier_number?: string;
}

export interface ProfileCompany {
  id: number;
  uuid: string;
  company_id: number;
  status_id: number;
  certificates?: any;
  name?: any;
  founded_in?: any;
  history?: any;
  history_ptbr?: any;
  acting?: any;
  acting_ptbr?: any;
  video_link?: any;
  tour_link?: any;
  images?: any;
  status?: any;
  image?: File;
}

export interface UpdateProfileCompany {
  name: string;
  status_id: number;
  since: number;
  history: string;
  history_ptbr: string;
  acting: string;
  acting_ptbr: string;
  video_link: string;
  tour_link: string;
  certificates: any;
}

export interface ReturnProfileCompany {
  data: ProfileCompany;
}

export interface UserList {
  status_profile: string;
  profile_id: number;
  id: number;
  name: string;
  email: string;
  created_at?: string | null;
  companies?: any;
}
export interface ResponseUser {
  data: UserList[];
  links: Links;
  meta: Meta;
}

class CompaniesService {
  static async newCompany(company: IPropsSaveCompany): Promise<void> {
    const { data } = await HttpClient.api.post(`/companies`, { ...company });

    return data;
  }

  static async getUsersByCompany(page: number, companyId: number | string): Promise<ResponseUser> {
    const { data } = await HttpClient.api.get(
      `/users?page=${page}&perPage=${20}&company_id=${companyId === undefined ? '' : companyId}`
    );

    return data;
  }

  static async getUsersInspector(page: number): Promise<ResponseUser> {
    const { data } = await HttpClient.api.get(
      `users/inspectors?page=${page}&perPage=${100}`
    );

    return data;
  }

  static async changeCompany(company: IPropsSaveCompany, id: string): Promise<void> {
    const { data } = await HttpClient.api.put(`/companies/${id}`, { ...company });

    return data;
  }

  static async latestUpdated(): Promise<ICompaniesService> {
    const { data } = await HttpClient.api.get('companies/business/latestUpdated');

    return data;
  }

  static async getCompanies(
    name: string,
    rules: string,
    page: number,
    perPage: number,
    status_profile?: string | number,
    certificates?: any[],
    order_by = 'name',
    sort = 'asc',
    by_preferences = false
  ): Promise<ICompanyServiceResponse> {
    const { data } = await HttpClient.api.get(
      `/companies?name=${name === undefined ? '' : name}&rule=${rules === undefined ? '' : rules}&status_profile=${status_profile === undefined ? '' : status_profile
      }&certificates=${certificates ? window.btoa(JSON.stringify(certificates)) : ''
      }&page=${page}&perPage=${perPage}&order_by=${order_by}&sort=${sort}&by_preferences=${by_preferences}`
    );

    return data;
  }

  static async getCompanieProfile(companyUuid: string): Promise<ReturnProfileCompany> {
    const { data } = await HttpClient.api.get(`companies/${companyUuid}/profile`);
    return data;
  }

  static async updateCompanyProfile(company: UpdateProfileCompany, id: string): Promise<void> {
    const { data } = await HttpClient.api.put(`/companies/${id}/profile`, { ...company });

    return data;
  }

  static async uploadImageToProfile(image: File, uuid: string): Promise<void> {
    const formData = new FormData();

    formData.append('image', image || '');

    await HttpClient.api.post(`/companies/${uuid}/profile/image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static async profileImages(images: any, uuid: string): Promise<any> {
    const formData = new FormData();
    if (images) images.forEach((file: any) => formData.append('images[]', file));
    const { data: result } = await HttpClient.api.post(`/companies/${uuid}/profile/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return result;
  }

  static async deleteImage(idImage: string, uuid: string): Promise<void> {
    await HttpClient.api.delete(`/companies/${uuid}/profile/images/${idImage}`);
  }

  static async getCompanyById(id: string): Promise<any> {
    const { data } = await HttpClient.api.get(`/companies/${id}`);

    return data;
  }
}

export default CompaniesService;
