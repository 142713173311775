import React from 'react';
import { ImageProps } from 'react-bootstrap';

import LogoPng from '../../statics/logo-wood.png';
import LogoFooter from '../../statics/logo-header.png';

interface IProp extends ImageProps {
  cy: string;
  isFooter?: boolean;
}

const Logo = ({ cy, isFooter = false, ...props }: IProp): React.ReactElement => (
  <>
    <>{!isFooter && <img src={LogoPng} alt="" {...props} data-cy={cy} className="w-auto" />}</>
    <>{isFooter && <img src={LogoFooter} alt="" {...props} data-cy={cy} className="w-auto" />}</>
  </>
);

export default Logo;
