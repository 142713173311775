import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Col, Image } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';

import PrivateRoute from '../components/PrivateRoute';
import { providerRoutes } from './providerRoutes';
import { customerRoutes } from './customerRoutes';

import Loader from '../components/Loader';
import Header from '../components/Header';
import Modal from '../components/Modal';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import Button from '../components/Button';
import ModalPreferences from '../components/ModalPreferences';
import createScript from '../utils/createScript';
import { StatusProfile } from '../enums/statusProfile';
import CheckCircle from '../statics/check-circle.svg';
import ICI from '../statics/Icon.png';
import { useLoader } from '../contexts/LoaderContext';
import UsersService from '../services/users.service';
import './styles.scss';

const Routes: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { user, signOut, updateUser } = useAuth();
  const { setShowLoader } = useLoader();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);

  const { boot, update, shutdown } = useIntercom();

  React.useEffect(() => {
    if (user?.status_profile !== StatusProfile.STATUS_APPROVED && user?.profileId !== 9) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (user?.profileId === 9 && !user?.accept_privacy_policies_at && !user.first_login_at) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [user]);

  const handleChangePrivacyPolicies = React.useCallback(async (): Promise<void> => {
    try {
      setShowLoader(true);
      if (user) {
        await UsersService.setPrivacyPolicies();
        const newUser = await UsersService.checkToken();
        await updateUser(newUser);
        setShowLoader(false);
      }
    } catch (err) {
      console.error(err);
      setShowLoader(false);
    }
  }, [setShowLoader, user, updateUser]);

  const renderRoutesProvider = (): React.ReactNode =>
    providerRoutes.map((route) => {
      if (route.public) {
        return <Route key={route.path} {...route} exact />;
      }
      return <PrivateRoute key={route.path} {...route} exact />;
    });

  const renderRoutesCustomer = (): React.ReactNode =>
    customerRoutes.map((route) => {
      if (route.public) {
        return <Route key={route.path} {...route} exact />;
      }
      return <PrivateRoute key={route.path} {...route} exact />;
    });

  const renderRoutes = (): React.ReactNode => {
    if (!window.$sleek && user && typeof window !== 'undefined') {
      window.LOQ = window.LOQ || [];
      window.LOQ.push([
        'ready',
        async (LO: {
          $internal: { ready: (arg0: string) => any };
          visitor: { identify: (arg0: string, arg1: any) => void };
        }) => {
          await LO.$internal.ready('visitor');
          LO.visitor.identify(user.id.toString(), { email: user.email, Name: user.displayName });
        },
      ]);
      window.$sleek = [];
      window.SLEEK_PRODUCT_ID = 639773341;
      createScript('https://client.sleekplan.com/sdk/e.js');
    }

    if (user?.profileId >= 9) {
      return renderRoutesProvider();
    }

    if (user?.profileId > 3 && user?.profileId < 9) {
      return renderRoutesCustomer();
    }

    return renderRoutesProvider();
  };

  React.useEffect(() => {
    if (user) {
      boot();
    } else {
      shutdown();
    }
  }, [boot, user, update, shutdown]);

  return (
    <>
      {user && user?.profileId === 9 && <ModalPreferences show={user && show} setShow={setShow} user={user || null} />}

      <Modal show={user && isOpen} size="lg" isCrud className="mt-4">
        <Container className="containerModalPermission  mb-4">
          <Col>
            <Image src={CheckCircle} />
          </Col>
          <Col>
            <h3>{t('messages.loadingVerify')}</h3>
          </Col>
          <Col>
            <p>
              {t('messages.textExplication')} <br /> <br />
              {t('messages.detailsAccount')}
            </p>
          </Col>

          <Col className="colButtons">
            <Button
              cy="btn-save"
              type="button"
              variant="transparent-gray"
              onClick={() => {
                signOut();
                setIsOpen(!isOpen);
              }}
            >
              {t('messages.logout')}
            </Button>
            <span>{t('language') === 'USA' ? 'or' : 'ou'}</span>
            <Button
              cy="btn-cancel"
              type="button"
              variant="transparent-green"
              onClick={() => window.open('https://api.whatsapp.com/send?phone=5541988539903')}
            >
              {t('messages.contacUs')}
            </Button>
          </Col>
        </Container>
      </Modal>
      <Container className="p-0">
        <React.Suspense fallback={<Loader />}>
          <Header isLogged={!!user} />
          {user && user?.profileId === 9 && !user?.accept_privacy_policies_at && user?.first_login_at && (
            <div className="dialog" role="dialog" aria-labelledby="dialog-title">
              <Col className="d-flex alignItemsDialog">
                <h5>
                  <img src={ICI} alt="ic" />
                  {t('labels.the')}
                  <span onClick={() => window.open('https://www.woodflow.com.br/privacy-policy/')} role="presentation">
                    {t('footer.labelPrivacyPolicy')}
                  </span>
                  {t('labels.available')}
                </h5>
                <Col className="d-flex align-items-center justify-content-end">
                  <Button cy="btn-cancel" type="button" onClick={() => handleChangePrivacyPolicies()}>
                    {t('labels.acceptAndReading')}
                  </Button>
                </Col>
              </Col>
            </div>
          )}

          <Switch>{renderRoutes()}</Switch>
          <Footer />
        </React.Suspense>
      </Container>
    </>
  );
};

Routes.defaultProps = { public: false };

export default Routes;
