import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, RouteProps, RouteComponentProps, generatePath } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import toastMsg, { ToastType } from '../../utils/toastMsg';

type IPrivateRoute = {
  component: React.FunctionComponent<RouteComponentProps>;
} & RouteProps;

export default function PrivateRoute({ component: Component, ...rest }: IPrivateRoute): React.ReactElement {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();

  if (!user) return <Redirect to={generatePath('/login/:hash', { hash: rest.location?.pathname || '' })} />;

  if (user && user?.profileId === 10) {
    toastMsg(ToastType.Error, t('messages.errorInspetor'));
    signOut();
  }

  if (user && user?.profileId <= 3) {
    toastMsg(ToastType.Error, t('messages.accessNotAutorized'));
    signOut();
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
