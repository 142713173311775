import React from 'react';
import { Modal } from 'react-bootstrap';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';

import Button from '../Button';

import './styles.scss';

export interface IModal {
  show: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  title?: string;
  size: 'sm' | 'lg' | 'xl';
  children?: React.ReactNode;
  titleButtonPrimary?: string;
  titleButtonSecondary?: string;
  disabledBtnPrimary?: boolean | false;
  disabledBtnSecondary?: boolean | false;
  className?: string;
  isCrud?: boolean;
  colorIcon?: boolean;
  image?: any;
}

const DataModal = ({
  show,
  handleClose,
  handleSubmit,
  title,
  size,
  children,
  titleButtonPrimary,
  titleButtonSecondary,
  disabledBtnPrimary,
  disabledBtnSecondary,
  className,
  isCrud = false,
  colorIcon = false,
  image,
}: IModal): React.ReactElement => (
  <Modal show={show} backdrop="static" size={size} className={className ? classNames(className) : 'modal'}>
    <Modal.Header className="d-flex">
      {image}
      <Modal.Title>{title}</Modal.Title>
      {isCrud ? (
        <></>
      ) : (
        <Modal.Title>
          <Button
            cy="test-modal-bt1"
            disabled={disabledBtnSecondary}
            type="submit"
            onClick={handleClose}
            variant="btn-transparent"
          >
            <HiX color={colorIcon ? 'black' : 'white'} /> {titleButtonSecondary}
          </Button>
        </Modal.Title>
      )}
    </Modal.Header>
    <Modal.Body>{children}</Modal.Body>
    {handleSubmit ? (
      <Modal.Footer>
        <Button cy="test-modal-bt2" disabled={disabledBtnPrimary} type="submit" onClick={handleSubmit}>
          {titleButtonPrimary}
        </Button>
      </Modal.Footer>
    ) : (
      <></>
    )}
  </Modal>
);

export default DataModal;
