import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { LoaderProvider } from './contexts/LoaderContext';
import { BusinessProvider } from './contexts/FilterBusinessContext';
import { SuppliersProvider } from './contexts/FilterSuppliersContext';

import Routes from './routes';

import './translate/i18n';
import { AuthProvider } from './contexts/AuthContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';

const App: React.FunctionComponent = () => (
  <AnalyticsProvider>
    <Router>
      <AuthProvider>
        <LoaderProvider>
          <IntercomProvider appId={String(process.env.REACT_APP_INTERCOM_APP_ID)}>
            <BusinessProvider>
              <SuppliersProvider>
                <Routes />
              </SuppliersProvider>
            </BusinessProvider>
          </IntercomProvider>
        </LoaderProvider>
      </AuthProvider>
    </Router>
  </AnalyticsProvider>
);

export default App;
