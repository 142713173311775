import React, { useState, useContext } from 'react';
import Loader from '../../components/Loader';

interface IContext {
  setShowLoader: (showLoader: boolean) => void;
  showLoader: boolean;
}

interface IProvider {
  children: React.ReactNode | React.ReactNode[];
}

const LoaderContext = React.createContext<IContext>({} as IContext);

export function useLoader(): IContext {
  return useContext(LoaderContext);
}

export function LoaderProvider({ children }: IProvider): React.ReactElement {
  const [showLoader, setShowLoader] = useState(false);

  const value = {
    showLoader,
    setShowLoader,
  };

  return (
    <LoaderContext.Provider value={value}>
      {children} {showLoader && <Loader />}
    </LoaderContext.Provider>
  );
}
